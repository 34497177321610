import { Component } from 'react';
import styled from 'styled-components';
import { App, Article, color, Header, Main, breakpoint } from '@shortlyster/ui-kit';
import { DisabledFormStyles } from 'src/features/various/forms/Form';
import Navigation from '../Header/Navigation';

const Footer = styled.footer`
  background: ${color('white')};
  height: 5em;
  margin-top: 2em;

  @media ${breakpoint('maxMobile')} {
    height: 0;
  }
`;

const StyledMain = styled(Main)`
  background: ${p => p.theme.global.colors.primary5};
  height: 100%;
  min-height: 100vh;
  max-width: 100%;
  align-items: center;
  justify-content: center;

  @media ${breakpoint('maxMobile')} {
    padding: 0;
  }

  ${Article} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

type Props = {
  params?: any;
  location: string;
  children?: any;
  isAuthenticated?: boolean;
};

export default class AppLayout extends Component<Props> {
  componentDidUpdate(prevProps: Props) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children, isAuthenticated = true } = this.props;

    return (
      <App>
        <DisabledFormStyles />
        {isAuthenticated ? (
          <>
            <Header>
              <Navigation />
            </Header>
            <Main>
              <Article>{children}</Article>
            </Main>
            <Footer />
          </>
        ) : (
          <StyledMain>
            <Article>{children}</Article>
          </StyledMain>
        )}
      </App>
    );
  }
}
