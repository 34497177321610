import { config } from 'a-plus-forms';
import Layout from './Layout';

export { EmptyLayout, MiniLayout } from '@shortlyster/forms-kit';

export {
  TextInput,
  EmailInput,
  PasswordInput,
  PhoneInput,
  LocationSelect,
  Checkbox,
  Radios,
  Select,
  HiddenInput,
} from '@shortlyster/forms-kit';

export { default as Form } from './Form';
export * from './Form';
export { default as MonthPicker } from './MonthPicker';
export { default as SalarySelect } from './SalarySelect';
export { default as InstitutionsSelect } from './InstitutionsSelect';
export { default as SelectWorkArea } from './SelectWorkArea';
export { default as RelocationPicker } from './RelocationPicker';
export { default as LocationPicker } from './LocationPicker';
export { default as HorizontalSplit } from './HorizontalSplit';
export { default as CheckboxLabelAligned } from './CheckboxLabelAligned';
export { default as SkillSelect } from './SkillSelect';
export { default as QualificationSelect } from './QualificationSelect';
export { default as SegmentedControl } from './SegmentedControl';
export { default as LikeMe } from './LikeMe';
export { default as EitherOr } from './EitherOr';
export { default as Slider } from './Slider';
export { default as PairSlider } from './PairSlider';
export { default as HiloSlider } from './HiloSlider';
export { default as TimezoneSelect } from './TimezoneSelect';

config.DefaultLayout = Layout;
