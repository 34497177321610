import React, { useEffect, useState } from 'react';
import { useUser } from '@shortlyster/auth-layer';
import { useAuth0 } from '@auth0/auth0-react';
import { useAnalytics } from '@compono/analytics';
import useSkippableAuthQuery from '../../hooks/useSkippableQuery';
import { FetchCandidateProfile, FetchUserProfile } from './queries';
import { FetchCandidateProfileData, FetchUserProfileData, User } from './types';
import appAccessValidation from './appAccessValidation';

export const AuthFetcher: React.FC = ({ children }) => {
  const [ready, setReady] = useState<Boolean>(false);
  const [authUser, setUser] = useUser<User>();
  const { isLoading: isAuth0Loading } = useAuth0();
  const isCandidateUser: Boolean = appAccessValidation();
  const { data, loading, error } = useSkippableAuthQuery<
    FetchCandidateProfileData | FetchUserProfileData
  >(isCandidateUser ? FetchCandidateProfile : FetchUserProfile);
  const { identify } = useAnalytics();

  useEffect(() => {
    if (loading || isAuth0Loading) return;

    if (data?.profile && !error) {
      const candidateDetails = (data as FetchCandidateProfileData)?.candidateProfile?.details;
      const user = {
        ...data.profile,
        ...(candidateDetails || {}),
      };
      setUser(user as any).then(() => setReady(true));
      if (user?.id) {
        identify(user.id, {
          profile: { id: data.profile.id },
          userId: user.id,
          userType: 'candidate',
        });
      }
    } else if (authUser) {
      setUser(null).then(() => setReady(true));
    } else {
      setReady(true);
    }
  }, [loading, data]);

  return ready ? <>{children}</> : null;
};
