import gql from 'graphql-tag';

import { HeaderMessage, Message } from './types';

export type AppMessageCount = {
  applicationId: string;
  count: number;
};

export type NewMessagesCountsResponse = {
  candidateApplicationsNewMessagesCounts: AppMessageCount[];
};

export const NewMessagesCounts = gql`
  query NewMessagesCounts {
    candidateApplicationsNewMessagesCounts {
      applicationId
      count
    }
  }
`;

export type LatestApplicationMessagesResponse = {
  candidateApplicationsLatestMessages: HeaderMessage[];
};

export const LatestApplicationMessages = gql`
  query LatestApplicationMessages {
    candidateApplicationsLatestMessages {
      id
      application {
        id
        listing {
          id
          title
          company
        }
      }
      senderType
      subject
      hasBeenRead
      createdAt
      updatedAt
    }
  }
`;

export type ApplicationMessagesResponse = {
  candidateApplicationMessages: Message[];
};

export const ApplicationMessagesQuery = gql`
  query ApplicationMessages($applicationId: ID!) {
    candidateApplicationMessages(applicationId: $applicationId) {
      id
      applicationId
      attachments {
        id
        size
        filename
        url
      }
      subject
      message
      senderType
      sender {
        id
        firstName
        lastName
      }
      recipient {
        id
        firstName
        lastName
      }
      replyToId
      hasBeenRead
      disableReply
      createdAt
      updatedAt
    }
  }
`;

export const CandidateMarkAsRead = gql`
  mutation candidateMarkAsRead($messageIds: [ID!]) {
    candidateMarkAsRead(messageIds: $messageIds) {
      id
      senderType
      hasBeenRead
    }
  }
`;

export type CandidateMarkAsReadResponse = {
  candidateMarkAsRead: [Message];
};
