import { useEffect } from 'react';
import history from 'lib/history';

export type Props = {
  push?: boolean;
  to: string;
};

function Redirect({ to, push = false }: Props) {
  useEffect(() => {
    if (/^https?:/.test(to)) {
      history.changeDomainTo(to);
    } else if (push) {
      history.push(to);
    } else {
      history.replace(to);
    }
  }, [to]);

  return null;
}

export default Redirect;
