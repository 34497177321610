import styled from 'styled-components';
import { color } from '@shortlyster/ui-kit';

const Instruction = styled.p`
  color: ${color('dark-2')};
  font-style: italic;
  font-weight: bold;
`;

export default Instruction;
