import { OperationVariables, useQuery } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { useAuth0 } from '@auth0/auth0-react';

const useSkippableAuthQuery = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode
) => {
  // `isAuthenticated` meaning that is ready to get `access token`
  const { isAuthenticated, isLoading: auth0IsLoading } = useAuth0();
  const result = useQuery<TData, TVariables>(query, { skip: auth0IsLoading || !isAuthenticated });

  /* On the first render cycle, lazy query always returns loading false because the useEffect has
   * not yet invoked the query. This ensures that the loading will be true on the first cycle
   * by checking the skip prop directly and checking for data */
  const loading = auth0IsLoading || result.loading || (isAuthenticated && !result.called);

  return { ...result, loading };
};

export default useSkippableAuthQuery;
