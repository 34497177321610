import React, { FC, Component, ComponentType, Suspense, lazy } from 'react';
import { Router, Route } from 'react-enroute';

import { Sentry } from 'lib/Sentry';

import history from 'lib/history';
import { NotFound } from 'lib/errors';
import Redirect from 'lib/Redirect';
import { SignedInContainer, UnauthedContainer } from 'features/auth';
import App from 'src/features/layout/App';

const ActiveApplicationsPage = lazy(
  () => import('src/features/applications/ActiveApplicationsPage')
);

const ApplicationPage = lazy(() => import('src/features/application/ApplicationPage'));
const ApplyPage = lazy(() => import('src/features/apply/ApplyPage'));

const ApplyPageExternal = lazy(() => import('src/features/apply/ApplyPageExternalCandidate'));
const ClosedApplicationsPage = lazy(
  () => import('src/features/applications/ClosedApplicationsPage')
);
const DetailsPage = lazy(() => import('src/features/profile/details/DetailsPage'));
const PrerequisitePage = lazy(() => import('src/features/pre_requisite/PrerequisitePage'));
const ExperienceAndSkillsPage = lazy(
  () => import('src/features/profile/experience_and_skills/ExperienceAndSkillsPage')
);
const InterviewPage = lazy(() => import('src/features/interview/InterviewPage'));
const JobDutiesPage = lazy(() => import('src/features/profile/job_happiness/JobDutiesPage'));
const MessagesPage = lazy(() => import('src/features/messages/MessagesPage'));
const ProfileReview = lazy(() => import('src/features/profile/review/ReviewProfilePage'));
const QualificationsPage = lazy(() => import('src/features/profile/QualificationsPage'));
const RollbackHire = lazy(() => import('src/features/unhire/UnhirePage'));
const SignUpPage = lazy(() => import('src/features/sign_up/SignupPage'));
const SignOutPage = lazy(() => import('src/features/sign_out/SignOutPage'));
const WizardLayout = lazy(() => import('src/features/profile/layout/WizardLayout'));
const WorkEnvironmentPage = lazy(
  () => import('src/features/profile/job_happiness/WorkEnvironmentPage')
);
const WorkPreferencesPage1 = lazy(
  () => import('src/features/profile/job_happiness/work_preferences/WorkPreferencesPage1')
);
const WorkPreferencesPage2 = lazy(
  () => import('src/features/profile/job_happiness/work_preferences/WorkPreferencesPage2')
);
const WorkPreferencesResultsPage = lazy(
  () => import('src/features/profile/job_happiness/work_preferences/WorkPreferencesResultsPage')
);
const InterviewsPage = lazy(() => import('src/features/interviews/InterviewsPage'));
const NotificationRedirect = lazy(() => import('src/features/redirect/NotificationRedirect'));

const NotFoundPage = lazy(() => import('src/features/pages/NotFound'));
const FailWhalePage = lazy(() => import('src/features/pages/FailWhale'));
const TestingPage = lazy(() => import('src/features/pages/Testing'));
const JobSummary = lazy(() =>
  import('src/features/job_summary/JobSummary').then(m => ({ default: m.JobSummary }))
);

import { User } from 'lib/auth';
import { useUser } from '@shortlyster/auth-layer';
import VerifyRouteRedirect from 'routes/VerifyRouteRedirect';
import Verify from 'features/verify/Verify';

const Nothing: FC = ({ children }) => <>{children}</>;

type SwitchLayoutProps = { component: ComponentType; path: string; isAuthenticated: boolean };

const SwitchLayout: FC<SwitchLayoutProps> = ({ component: LayoutComponent, ...rest }) => (
  <Route {...rest} render={props => <LayoutComponent {...props} />} />
);

export default class AppRouter extends Component {
  state = { location: history.location.pathname, faceDown: null };

  componentDidMount() {
    window.onclick = (ev: Event) => {
      const e = ev || window.event;
      const targetElement = e.target || e.srcElement;
      if (targetElement instanceof Element && targetElement.tagName === 'A') {
        const href = targetElement.getAttribute('href');
        const target = targetElement.getAttribute('target') || '_self';

        if (!href) return false;

        // external link
        if (href.startsWith('http')) {
          window.open(href, target);
          return false;
        }

        // email link
        if (href.startsWith('mailto:')) {
          history.changeDomainTo(href);
          return false;
        }

        // relative link
        history.push(href);
        return false;
      }

      return true;
    };

    history.listen(location => this.setState({ location: location.pathname }));
  }

  componentDidCatch(error) {
    Sentry.captureException(error);
    this.setState({ faceDown: error });
  }

  resetError = () => {
    this.setState({ faceDown: undefined });
  };

  render() {
    const [user] = useUser<User>();
    const { faceDown, location } = this.state;

    if (
      user &&
      // Needs to be exactly false and not undefined, when profile details saved value is undefined
      user.emailVerified === false &&
      window.location.pathname !== '/verify' &&
      window.location.pathname !== '/signout'
    ) {
      return <VerifyRouteRedirect params={{ location }} />;
    }

    if (faceDown) {
      const error = faceDown;
      const isNotFound = error instanceof NotFound || error.message.includes('not found');
      const Page = isNotFound ? NotFoundPage : FailWhalePage;
      return (
        <App location={location}>
          <Page error={faceDown} reset={this.resetError} />
        </App>
      );
    }

    return (
      <Suspense fallback={<></>}>
        <Router location={location}>
          <Route path="/signout" component={SignOutPage} />
          <Route path="" component={UnauthedContainer}>
            <SwitchLayout path="" isAuthenticated={false} component={App}>
              <Router path="qa" component={TestingPage} />
              <Route path="signup" component={SignUpPage} />
              <Route path="l/:id" component={JobSummary} />
              <Route path="notification-redirect" component={NotificationRedirect} />
            </SwitchLayout>
          </Route>
          <Route path="" component={SignedInContainer}>
            <Route path="" component={App}>
              <Route path="verify" component={Verify} />
              <Route path="active" component={ActiveApplicationsPage} />
              {/* The invitations routes are deprecated */}
              <Route path="invitations" component={() => <Redirect to="/profile" push />} />
              <Route
                path="invitations/:id"
                component={({ params: { id } }: { params: { id: string } }) => (
                  <Redirect to={`/profile?applicationId=${id}`} push />
                )}
              />
              <Route path="closed" component={ClosedApplicationsPage} />
              <Route path="applications/:id" component={ApplicationPage} />
              <Route path="unhire/:id" component={RollbackHire} />
              <Route path="apply/:listingId" component={ApplyPage} />
              <Route path="apply-external/:slug" component={ApplyPageExternal} />
              <Route
                path="applications/:applicationId/prerequisite-questions"
                component={PrerequisitePage}
              />
              {/* Deprecated paths */}
              <Route path="drafts" component={() => <Redirect to="/profile" push />} />
              <Route path="signin" component={() => <Redirect to="/profile" push />} />
              <Route path="in-progress" component={() => <Redirect to="/profile" push />} />
              <Route path="" component={() => <Redirect to="/profile" push />} />

              <Route path="messages" component={MessagesPage} />
              <Route path="messages/:applicationId" component={MessagesPage} />
              <Route path="messages/application/:applicationId" component={MessagesPage} />

              <Route path="interviews" component={InterviewsPage} />
              <Route path="interviews/past" component={InterviewsPage} />
              <Route path="interviews/:id" component={InterviewPage} />

              <Route path="profile" component={WizardLayout}>
                <Route path="" component={ProfileReview} />
                <Route path="details" component={DetailsPage} />
                <Route path="experience-and-skills" component={ExperienceAndSkillsPage} />
                <Route path="qualifications" component={QualificationsPage} />

                <Route path="job-happiness" component={Nothing}>
                  <Route path="work-environment" component={WorkEnvironmentPage} />
                  <Route path="job-duties" component={JobDutiesPage} />

                  <Route path="approach-to-work" component={WorkPreferencesResultsPage} />
                  <Route path="approach-to-work-1" component={WorkPreferencesPage1} />
                  <Route path="approach-to-work-2" component={WorkPreferencesPage2} />
                </Route>
              </Route>

              <Route path="(.*)" component={NotFoundPage} />
            </Route>
          </Route>
        </Router>
      </Suspense>
    );
  }
}
