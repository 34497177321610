type Props = {
  children: String;
};

const BrowserTitle = ({ children }: Props) => {
  if (typeof children === 'string') document.title = `Compono - ${children}`;
  return null;
};

export default BrowserTitle;
