const getMetaTag = (name: string) => {
  const foundMeta = document.querySelector(`meta[name="${name}"]`);
  return foundMeta ? foundMeta.getAttribute('content') : process.env[name];
};

/* eslint-disable import/no-mutable-exports */
let API_URL = getMetaTag('API_URL') || 'http://localhost:3010';
let AUTH_URL = getMetaTag('AUTH_URL');
let PUBLIC_URL = getMetaTag('PUBLIC_URL');
/* eslint-enable import/no-mutable-exports */
const CRONOFY_CLIENT_DATACENTER = getMetaTag('CRONOFY_CLIENT_DATACENTRE') || 'au';
const ENVIRONMENT = getMetaTag('ENVIRONMENT') || 'development';
const RELEASE = getMetaTag('RELEASE') || 'development';
const GITHUB_SHA = getMetaTag('GITHUB_SHA') || 'SHA_NOT_SET';

const devDefaultClient = 'XrZ5dv4Y1QIzRlIBbq5tfkXBSzCjRuWa'; // compono-client-shortlyster-candidate-main
const AUTH0_SPA_CANDIDATE_CLIENT_ID =
  getMetaTag('AUTH0_SPA_CANDIDATE_CLIENT_ID') || devDefaultClient;
const AUTH0_ENV_NAME = getMetaTag('AUTH0_ENV_NAME');
const AUTH0_CUSTOM_DOMAIN = getMetaTag('AUTH0_CUSTOM_DOMAIN') || 'auth.dev.compono.dev';

const {
  location: { hostname },
} = document;

// when running in acceptar and need to change localhost -> dockerhost
if (API_URL && API_URL.endsWith('localhost:3010') && !API_URL.includes(hostname)) {
  API_URL = API_URL.replace('localhost:', `${hostname}:`);
  AUTH_URL = AUTH_URL?.replace('localhost:', `${hostname}:`);
  PUBLIC_URL = PUBLIC_URL?.replace('localhost:', `${hostname}:`);
}

const HEADLESS_TESTING = navigator.userAgent.includes(' HeadlessChrome/');

const SENTRY_DSN = 'https://8e04b08d439944fc9ff818f3c4894cdd@o360657.ingest.sentry.io/5217239';

const MIXPANEL_TEST_TOKEN = 'cd090c9a3b2bbc65ca3beaf9ce5741ce';
const MIXPANEL_PROD_TOKEN = 'fdfb701ade5d931d1894a69f75e7c085';

const ZENDESK_PROD_TOKEN = '829b58a4-cb24-4035-94ce-b782c8007774';

export {
  API_URL,
  AUTH_URL,
  PUBLIC_URL,
  CRONOFY_CLIENT_DATACENTER,
  HEADLESS_TESTING,
  GITHUB_SHA,
  ENVIRONMENT,
  RELEASE,
  SENTRY_DSN,
  AUTH0_SPA_CANDIDATE_CLIENT_ID,
  AUTH0_CUSTOM_DOMAIN,
  AUTH0_ENV_NAME,
  MIXPANEL_TEST_TOKEN,
  MIXPANEL_PROD_TOKEN,
  ZENDESK_PROD_TOKEN,
};
