import React, { useState, useEffect } from 'react';
import { ApolloError, useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import { field } from 'a-plus-forms';
import {
  AsyncSelectMulti,
  EmptyLayout,
  SelectProps,
  LabelValueOption,
  GrommetLayout,
} from '@shortlyster/forms-kit';
import styled from 'styled-components';

import { RenderValue } from './RenderValue';
import { formatApolloError } from './utils';
import Spinner from '../features/various/Spinner';

type Skill = {
  id: string;
  name: string;
  score?: number;
};

const Container = styled.div`
  position: relative;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: calc((100% / 2) - 8px);
  left: 91%;
  z-index: 111;
  display: flex;
`;

export const FETCH_SKILLS = gql`
  query SearchSkills($search: String!) {
    skills(search: $search, page: 1) {
      id
      name
      score
    }
  }
`;

export const REHYDRATE_SKILLS = gql`
  query RehydrateSkills($ids: [ID]!) {
    skills: rehydrateSkills(id: $ids) {
      id
      name
    }
  }
`;

export const CREATE_NEW_SKILL = gql`
  mutation CreateSkill($name: String!) {
    createDraftSkill(name: $name) {
      id
      name
    }
  }
`;

export type SkillSelectProps = SelectProps & {
  exclude?: string[];
  allowNewOption?: boolean;
};

const skillToOption = (skill: Skill): LabelValueOption => ({
  value: skill!.id,
  label: skill!.name,
});

export const SkillSelectNew: React.FC<SkillSelectProps> = props => {
  const [showLoading, setShowLoading] = useState(false);

  const { exclude = [], allowNewOption = true } = props;
  const client = useApolloClient();

  const [errors, setErrors] = useState<string | undefined>(undefined);
  const [selectedSkillOptions, setSelectedSkillOptions] = useState<LabelValueOption[]>([]);

  const removeExclusions = (skillOptions: LabelValueOption[]) => {
    return skillOptions.filter(skillOption => !exclude.includes(skillOption.value));
  };

  const fetchOptions = async (search: string): Promise<LabelValueOption[]> => {
    if (!search) return [];

    setShowLoading(true);

    return client
      .query<{ skills: Skill[] }>({
        query: FETCH_SKILLS,
        variables: { search },
        /* caching causes problems with newly created options not appearing in search */
        fetchPolicy: 'network-only',
      })
      .then(({ data: { skills = [] } = {} }) => removeExclusions(skills.map(skillToOption)))
      .finally(() => setShowLoading(false));
  };

  const rehydrateOptions = (value: string[]): Promise<LabelValueOption[]> => {
    const ids = value.filter(id => !!id);
    if (ids.length === 0) return Promise.resolve([]);

    return client
      .query<{ skills: Skill[] }>({
        query: REHYDRATE_SKILLS,
        variables: { ids },
      })
      .then(({ data: { skills = [] } }: any) => {
        return skills.map(skillToOption);
      });
  };

  const createNewSkill = (name: string): Promise<LabelValueOption> => {
    setShowLoading(true);
    return client
      .mutate<{ createDraftSkill: Skill }>({
        mutation: CREATE_NEW_SKILL,
        variables: { name },
      })
      .then(({ data: { createDraftSkill: skill } }: any) => {
        setErrors(undefined);
        return skillToOption(skill);
      })
      .catch((error: ApolloError) => {
        setErrors(formatApolloError(error));
        throw error;
      })
      .finally(() => setShowLoading(false));
  };

  const newOptionPrompt = (search: string) => {
    const isMatch = selectedSkillOptions.some(
      option => option.label.toLowerCase() === search.toLowerCase()
    );

    return !isMatch ? `Create new skill "${search}"` : undefined;
  };

  useEffect(() => {
    rehydrateOptions(exclude).then(options => {
      if (options && options.length) {
        setSelectedSkillOptions(options);
      }
    });
  }, [exclude]);

  useEffect(() => {
    if (!selectedSkillOptions.length) {
      rehydrateOptions(exclude).then(options => {
        if (options && options.length) {
          setSelectedSkillOptions(options);
        }
      });
    }
  }, [selectedSkillOptions]);

  return (
    <Container>
      <LoaderWrapper>{showLoading && <Spinner />}</LoaderWrapper>
      <AsyncSelectMulti
        {...props}
        minSearchChars={1}
        layout={EmptyLayout}
        error={errors}
        fetchOptions={fetchOptions}
        rehydrateOptions={rehydrateOptions}
        createNewOption={allowNewOption ? createNewSkill : undefined}
        newOptionPrompt={newOptionPrompt}
        renderValue={RenderValue}
      />
    </Container>
  );
};

export default field<SkillSelectProps>({ layout: GrommetLayout })(SkillSelectNew);
