/* eslint-disable no-unused-vars */
import * as React from "react";
import * as PropTypes from "prop-types";
import { field, OptionsProps } from "a-plus-forms";
import { GrommetLayout } from "@shortlyster/forms-kit";
import { SelectMulti } from "@shortlyster/ui-kit";
import { Tag } from "@shortlyster/ui-kit/dist/components/Select/Tag";
import { ApolloClient } from "apollo-client";
import { Box, CheckBox, Text } from "grommet";
import { FormDown, FormUp } from "grommet-icons";
import GET_WORK_AREAS from "./get_work_areas";
import { memoizeSkillField, memoizeWorkArea } from "./memoize";

const isIE = /*@cc_on!@*/ false || !!(document as any).documentMode;

export type WorkArea = {
  id: string;
  name: string; // eslint-disable-line no-restricted-globals
};

export type SkillField = {
  id: string;
  name: string; // eslint-disable-line no-restricted-globals
  workAreas: WorkArea[];
};

export interface Option {
  label: string;
  value: string;
  type: "skillField" | "workArea";
  workAreas?: Option[];
  fieldId?: string;
  fieldLabel?: string;
}

export interface LabelValueOption {
  label: string | any;
  value: string;
  disabled?: boolean;
}

export type BuildOptions = {
  skillFields: SkillField[];
  openFields: string[];
  canSelectGroup: boolean;
};

export type UpdateValue = ({ value }: { value: string }) => void;

export type OptionState = {
  active: boolean;
  disabled: boolean;
  selected: boolean;
};

export type Props = OptionsProps & {
  value?: string[];
  onChange?: (value: string[]) => void;
  canSelectGroup?: boolean;
  skillFields: any;
  disabled?: boolean;
  placeholder?: string;
};

export type State = {
  skillFields: SkillField[];
  openFields: string[];
  options: Option[];
  loading: boolean;
};

const skillFieldLabel = (option: Option): string =>
  `(${option.workAreas.length}) ${option.label}`;

const showField = (option: Option) => option.label === "Other";
const workAreaLabel = (option: Option): string =>
  showField(option) ? `${option.label} in ${option.fieldLabel}` : option.label;

class SelectWorkArea extends React.Component<Props, State> {
  static contextTypes = {
    client: PropTypes.object, // eslint-disable-line
  };

  context: { client: ApolloClient<any> };

  state: State = {
    skillFields: [],
    openFields: [],
    options: [],
    loading: true,
  };

  componentDidMount() {
    const { value = [], onChange, canSelectGroup } = this.props;
    const { client } = this.context;

    client
      .query({ query: GET_WORK_AREAS })
      .then(({ data: { skillFields = [] } }) => {
        const options = this.buildOptions({
          skillFields,
          openFields: [],
          canSelectGroup,
        });
        this.setState({ skillFields, options, loading: false }, () =>
          onChange?.(value)
        );
      });
  }

  areaChecked = (areaId: string): boolean =>
    (this.props.value || []).includes(areaId);

  fieldStatus = (
    fieldId: string
  ): { checked: boolean; indeterminate?: boolean } => {
    const { skillFields } = this.state;
    let checked = true;
    let indeterminate = false;

    const skillField = skillFields.find((f) => f.id === fieldId);
    if (!skillField) {
      return { checked: false };
    }
    skillField.workAreas.forEach((workArea) => {
      if (this.areaChecked(workArea.id)) {
        indeterminate = true;
      } else {
        checked = false;
      }
    });

    return checked ? { checked } : { checked: false, indeterminate };
  };

  fieldAreaValues = (fieldId: string): string[] =>
    this.state.skillFields
      .find((skillField) => skillField.id === fieldId)
      .workAreas.map(({ id }) => id);

  buildValue = (values: Option[]): Option[] => [
    ...this.state.skillFields
      .filter((skillField) => this.fieldStatus(skillField.id).checked)
      .map(memoizeSkillField),
    ...values.filter((workArea) => !this.fieldStatus(workArea.fieldId).checked),
  ];

  renderValue = (value: Option[], updateValue: UpdateValue) => {
    const removeOption = (option: Option) => (): void => {
      if (option.type === "skillField") {
        const valuesToRemove = this.fieldAreaValues(option.value);
        updateValue({
          value: value.filter((o) => !valuesToRemove.includes(o.value)),
        });
      } else {
        updateValue({ value: value.filter((o) => o.value !== option.value) });
      }
    };

    const valuesToRender = this.buildValue(value);

    return (
      valuesToRender.length > 0 && (
        <Box direction="row" wrap={true}>
          {valuesToRender.map((option: Option) => (
            <Tag
              key={option.value}
              {...option}
              label={
                option.type === "skillField"
                  ? skillFieldLabel(option)
                  : workAreaLabel(option)
              }
              remove={removeOption(option)}
            />
          ))}
        </Box>
      )
    );
  };

  renderOption = (
    option: Option,
    index: number,
    options: Option[],
    state: OptionState
  ) => {
    const { canSelectGroup } = this.props;
    const { openFields } = this.state;
    if (option.type === "skillField") {
      const { label } = option;
      const isOpen = openFields.indexOf(option.value) >= 0;
      const toggleOpen = () => this.openCloseGroup(option.value);
      const { checked, indeterminate } = this.fieldStatus(option.value);

      return (
        <Box
          data-testid="select-work-area-toggle-box"
          key={label}
          direction="row"
          pad={{ left: "small" }}
          justify="between"
          align="center"
          style={{ cursor: "pointer" }}
          // eslint-disable-next-line consistent-return
          onClick={(event) => {
            if (canSelectGroup) return false;
            // This doesn't work in IE
            event.preventDefault();
            event.stopPropagation();
            toggleOpen();
          }}
        >
          {canSelectGroup ? (
            <CheckBox
              readOnly
              checked={checked}
              indeterminate={indeterminate}
              label={label}
            />
          ) : (
            <Text>{label}</Text>
          )}
          <Box
            data-testid="select-work-area-toggle-inner-box"
            align="center"
            justify="center"
            width="2.5rem"
            height="2.5rem"
            onClick={(event) => {
              // This doesn't work in IE
              event.preventDefault();
              event.stopPropagation();
              toggleOpen();
            }}
          >
            {isOpen ? <FormUp size="small" /> : <FormDown size="small" />}
          </Box>
        </Box>
      );
    }

    return (
      <Box
        key={option.value}
        direction="row"
        pad={{
          left: canSelectGroup ? "large" : "small",
          right: "xsmall",
          vertical: "xsmall",
        }}
      >
        <CheckBox readOnly checked={state.selected} label={option.label} />
      </Box>
    );
  };

  openCloseGroup = (fieldId: string): void =>
    this.setState(({ skillFields, openFields }) => {
      const index = openFields.indexOf(fieldId);
      if (index === -1) {
        openFields.push(fieldId);
      } else {
        openFields.splice(index, 1);
      }

      const { canSelectGroup } = this.props;
      const options = this.buildOptions({
        skillFields,
        openFields,
        canSelectGroup,
      });
      return { openFields, options };
    });

  buildOptions = ({ skillFields, openFields }: BuildOptions): Option[] =>
    skillFields.reduce((opts: Option[], skillField: SkillField) => {
      const { id, workAreas } = skillField;
      opts.push(memoizeSkillField(skillField));
      // if this is IE, always expand everything
      if (isIE || openFields.indexOf(id) >= 0) {
        workAreas.forEach((workArea) =>
          opts.push(memoizeWorkArea(workArea, skillField))
        );
      }

      return opts;
    }, []);

  rehydrate = (id: string): Option => {
    const { skillFields } = this.state;
    for (let i = 0; i < skillFields.length; i++) {
      for (let j = 0; j < skillFields[i].workAreas.length; j++) {
        if (skillFields[i].workAreas[j].id === id) {
          return memoizeWorkArea(skillFields[i].workAreas[j], skillFields[i]);
        }
      }
    }
    return null;
  };

  handleChange = (newSelectedValues: Option[]): void => {
    const { onChange, value = [], disabled } = this.props;
    const { loading } = this.state;
    if (loading || disabled) return;

    const selectedSkillFields = newSelectedValues.filter(
      (o) => o.type === "skillField"
    );
    const selectedWorkAreas = newSelectedValues.filter(
      (o) => o.type === "workArea"
    );

    /* clicking a skill field includes the skill field in the onChange value,
     * but we need to convert this into purely work area values */
    const newValue = selectedSkillFields
      .reduce((result, skillField) => {
        const uncheckedWorkAreas = skillField.workAreas.filter(
          (workArea) => !newSelectedValues.includes(workArea)
        );

        /* IF only some of the work areas for the field are checked, add the missing ones */
        if (uncheckedWorkAreas.length > 0)
          return [...result, ...uncheckedWorkAreas];
        /* all of the work areas were already checked, so remove them all (de-select) */
        return result.filter(
          (workArea) => !skillField.workAreas.includes(workArea)
        );
      }, selectedWorkAreas)
      .map((o) => o.value);

    if (
      value.length !== newValue.length ||
      JSON.stringify(newValue) !== JSON.stringify(value)
    ) {
      onChange(newValue);
    }
  };

  render() {
    const { value = [], placeholder, disabled } = this.props;
    const { options, loading } = this.state;
    const valueOptions = value.map(this.rehydrate).filter(Boolean);

    return (
      <SelectMulti
        value={valueOptions}
        options={options}
        onChange={this.handleChange}
        renderOption={this.renderOption}
        renderValue={this.renderValue}
        // @ts-ignore
        disabled={disabled || loading}
        placeholder={loading ? "Loading..." : placeholder}
      />
    );
  }
}

export default field({ layout: GrommetLayout })(SelectWorkArea);
