import { FC } from 'react';
import { useUser } from '@shortlyster/auth-layer';

import { User } from 'lib/auth';
import history from 'lib/history';
import Redirect from 'lib/Redirect';
import appAccessValidation from 'src/lib/auth/appAccessValidation';

const UnauthedContainer: FC<{ params: { id?: string } }> = ({ params, children }) => {
  const isCandidateUser = appAccessValidation();
  const [user] = useUser<User>();
  const { listingId: queryListingId, redirect } = history.location.query;
  const listingId = queryListingId || (/\/l\/*/.test(document.location.pathname) && params.id);

  if (user) {
    if (redirect) return <Redirect to={redirect} />;
    if (listingId) {
      if (isCandidateUser) {
        return <Redirect to={`/apply/${listingId}`} />;
      }
      return <Redirect to={`/profile?listingId=${listingId}`} />;
    }
    return <Redirect to="/profile" />;
  }

  return <>{children}</>;
};

export default UnauthedContainer;
