/* eslint global-require: 0 */
import ReactDOM from 'react-dom';
import { ga, mixpanel } from '@shortlyster/analytics-kit';
import { initSentry } from 'lib/Sentry';
import * as zendesk from '@shortlyster/zendesk-kit';
import { Box, Layer } from 'grommet';

import {
  HEADLESS_TESTING,
  ENVIRONMENT,
  GITHUB_SHA,
  RELEASE,
  SENTRY_DSN,
  MIXPANEL_TEST_TOKEN,
  MIXPANEL_PROD_TOKEN,
  ZENDESK_PROD_TOKEN,
} from 'lib/env';
import App from './App';

const SoCringeAvoidTreeShakingGrommetLayerUsedInUiKit = () => (
  <>
    <Layer full={false} modal={false} style={{ display: 'none' }} />
    <Box style={{ display: 'none' }} />
  </>
);

const rootEl = document.getElementById('app');

ReactDOM.render(
  <>
    <SoCringeAvoidTreeShakingGrommetLayerUsedInUiKit />
    <App />
  </>,
  rootEl
);

const setSuperProperties = (params: PlainObject = {}): void => {
  const { mixpanel: mx } = window;
  if (!mx) return;

  mx.register(params);
};

/* Setup Analytics */

if (process.env.NODE_ENV === 'production' && !HEADLESS_TESTING) {
  const PROD_HOSTNAME = /^\w*\.compono\.com$/;
  const isProduction = PROD_HOSTNAME.test(window.location.hostname);

  if (isProduction) ga.init();
  // we want to remove this initalization of mixpanel eventually,
  // but we need to make sure everything it inits is brought over
  // and updated first. it does not conflict with @compono/analytics
  mixpanel.configure({ trackPageViews: isProduction, debug: false });
  mixpanel.init(isProduction ? MIXPANEL_PROD_TOKEN : MIXPANEL_TEST_TOKEN);

  // TEMPORARY TO DEBUG MIXPANEL IN PROD
  setSuperProperties({
    release: GITHUB_SHA,
  });
  if (isProduction) zendesk.init(ZENDESK_PROD_TOKEN);

  initSentry({ ENVIRONMENT, RELEASE, SENTRY_DSN });
}
