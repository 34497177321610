import qs from 'qs';
import { createBrowserHistory } from 'history';

export const browserHistory = createBrowserHistory();

const getQuery = () => {
  const { search } = browserHistory.location;
  if (!search || search.length <= 1) return {};
  return qs.parse(search.substring(1));
};

const getLocation = () => ({
  ...browserHistory.location, // provides a parsed query object in .location.query
  get query() {
    const query = getQuery();
    if (query.page) query.page = Math.max(parseInt(query.page, 10), 1);
    return query;
  },
});

export default {
  goBack: browserHistory.goBack,
  listen: browserHistory.listen,
  push: browserHistory.push,
  replace: browserHistory.replace,
  get location() {
    return getLocation();
  },

  // a hack around, essentially JSDOM doesn't support navigation to new domains
  // and it doesn't allow to spoof the `.href` accessor either, so i'm declaring
  // a new method here, which we can mock in tests
  changeDomainTo(newDomainLocation) {
    if (process.env.NODE_ENV !== 'test') {
      document.location.href = newDomainLocation;
    }
  },
};
