import styled from 'styled-components';
import { Node } from 'react';

type Props = {
  leftSide: Node,
  rightSide: Node,
};

const Root = styled.div`
  @media (${p => p.theme.tabletLandscapeAndUp}) {
    display: flex;
  }
`;

const Item = styled.div`
  &:last-child {
    margin-top: ${p => p.theme.spacingFormLayout};
  }

  @media (${p => p.theme.tabletLandscapeAndUp}) {
    flex: 1;

    &:last-child {
      margin-left: ${p => p.theme.spacingFormLayout};
      margin-top: 0;
    }
  }
`;

const HorizontalSplit = ({ leftSide, rightSide }: Props) => (
  <Root>
    <Item>{leftSide}</Item>
    <Item>{rightSide}</Item>
  </Root>
);

export default HorizontalSplit;
