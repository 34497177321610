import styled from 'styled-components';
import { color } from '@shortlyster/ui-kit';

const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100vw;
  background: ${color('dark-2')};
  opacity: 0.6;
  top: 0;
  left: 0;
`;

export default Overlay;
