/* eslint-disable no-unused-vars */
import * as React from "react";
import * as PropTypes from "prop-types";
import { ApolloClient, ApolloError } from 'apollo-client'; // eslint-disable-line
import gql from "graphql-tag";
import { field } from "a-plus-forms";
import {
  AsyncSelectMulti,
  EmptyLayout,
  SelectProps,
  LabelValueOption,
  GrommetLayout,
} from '@shortlyster/forms-kit'; // eslint-disable-line
import { RenderValue } from "./RenderValue";
import { Skill } from './types'; // eslint-disable-line
import { formatApolloError } from "./utils";

export const FETCH_SKILLS = gql`
  query SearchSkills($search: String!) {
    skills(search: $search, page: 1) {
      id
      name
    }
  }
`;

export const REHYDRATE_SKILLS = gql`
  query RehydrateSkills($ids: [ID]!) {
    skills: rehydrateSkills(id: $ids) {
      id
      name
    }
  }
`;

export const CREATE_NEW_SKILL = gql`
  mutation CreateSkill($name: String!) {
    createDraftSkill(name: $name) {
      id
      name
    }
  }
`;

export type SkillSelectProps = SelectProps & {
  exclude?: string[];
  allowNewOption?: boolean;
};

const skillToOption = (skill: Skill): LabelValueOption => ({
  value: skill.id,
  label: skill.name,
});

class SkillSelect extends React.Component<SkillSelectProps> {
  static contextTypes = {
    client: PropTypes.shape({
      mutate: PropTypes.func.isRequired,
    }),
  };

  removeExclusions = (skillOptions: LabelValueOption[]) => {
    const { exclude = [] } = this.props;

    return skillOptions.filter(
      (skillOption) => !exclude.includes(skillOption.value)
    );
  };

  context: { client: ApolloClient<any> };

  state = { errors: undefined as string, selectedSkillOptions: [] };

  fetchOptions = (search: string): Promise<LabelValueOption[]> => {
    const { client } = this.context;

    return client
      .query<{ skills: Skill[] }>({
        query: FETCH_SKILLS,
        variables: { search },
        /* caching causes problems with newly created options not appearing in search */
        fetchPolicy: "network-only",
      })
      .then(({ data: { skills = [] } }) =>
        this.removeExclusions(skills.map(skillToOption))
      );
  };

  rehydrateOptions = (value: string[]): Promise<LabelValueOption[]> => {
    const { client } = this.context;

    const ids = value.filter((id) => !!id);
    if (ids.length === 0) return Promise.resolve([]);

    return client
      .query<{ skills: Skill[] }>({
        query: REHYDRATE_SKILLS,
        variables: { ids },
      })
      .then(({ data: { skills = [] } }: any) => {
        return skills.map(skillToOption);
      });
  };

  createNewSkill = (name: string): Promise<LabelValueOption> =>
    this.context.client
      .mutate<{ createDraftSkill: Skill }>({
        mutation: CREATE_NEW_SKILL,
        variables: { name },
      })
      .then(({ data: { createDraftSkill: skill } }: any) => {
        this.setState({ errors: undefined });
        return skillToOption(skill);
      })
      .catch((error: ApolloError) => {
        this.setState({ errors: formatApolloError(error) });
        throw error;
      });

  getSelectedStateOptions = (): void => {
    const { exclude = [] } = this.props;

    this.rehydrateOptions(exclude).then((options) => {
      if (options && options.length) {
        this.setState({ selectedSkillOptions: options });
      }
    });
  };

  newOptionPrompt = (search: string) => {
    const isMatch = this.state.selectedSkillOptions.some(
      (option) => option.label.toLowerCase() === search.toLowerCase()
    );

    return !isMatch ? `Create new skill "${search}"` : undefined;
  };

  componentDidUpdate(prevProps: any) {
    const { exclude = [] } = this.props;

    if (exclude.length !== prevProps.exclude.length) {
      this.getSelectedStateOptions();
    }
  }

  render() {
    const { allowNewOption = true } = this.props;
    const { selectedSkillOptions } = this.state;

    if (!selectedSkillOptions.length) {
      this.getSelectedStateOptions();
    }

    return (
      <AsyncSelectMulti
        {...this.props}
        layout={EmptyLayout}
        error={this.state.errors} // eslint-disable-line
        fetchOptions={this.fetchOptions}
        rehydrateOptions={this.rehydrateOptions}
        createNewOption={allowNewOption ? this.createNewSkill : undefined}
        newOptionPrompt={this.newOptionPrompt}
        renderValue={RenderValue}
      />
    );
  }
}

export default field<SkillSelectProps>({ layout: GrommetLayout })(SkillSelect);
