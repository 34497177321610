import styled from 'styled-components';
import { Caption, color } from '@shortlyster/ui-kit';
import { ComponoLogo } from './ComponoLogo';

export const Logo = styled('div')`
  display: flex;
  flex-direction: column;

  :hover,
  :focus {
    text-decoration: none;
  }

  svg {
    width: 120px;
    margin-top: -12px;
    margin-left: 30px;
  }
`;

const PoweredBy = styled(Caption).attrs(() => ({ children: 'Smart companies hire with' }))`
  font-size: 14px;
  color: ${color('dark-2')};
  align-self: center;
  margin-bottom: 5px;
`;

export const PoweredByLogo = () => (
  <Logo>
    <PoweredBy />
    <ComponoLogo />
  </Logo>
);
