import dateformat from 'dateformat';

type Props = {
  date?: Date | string;
  format?: string;
};

const DateFormatter = ({ date, format }: Props) => <>{date ? dateformat(date, format) : 'N/A'}</>;

DateFormatter.defaultProps = {
  format: 'dS mmm yyyy',
};

export default DateFormatter;
