export default Object.freeze({
  default: 'default' as 'default',
  large1x: 'large1x' as 'large1x',
  large2x: 'large2x' as 'large2x',
  large3x: 'large3x' as 'large3x',
  large4x: 'large4x' as 'large4x',
  medium: 'medium' as 'medium',
  small1x: 'small1x' as 'small1x',
  small2x: 'small2x' as 'small2x',
  small3x: 'small3x' as 'small3x',
});
