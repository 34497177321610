import { ThemeProvider } from '@shortlyster/ui-kit';

import { AuthFetcher, Auth0Wrapper, AuthorizedApolloProvider } from 'lib/auth';
import FakeLegacyApolloContext from 'lib/fake_apollo_context';
import Sentry from 'lib/Sentry';
import tokens from 'ui_kit/constants/tokens';
import GlobalStyles from 'styles/global_styles';
import { UIProvider } from '@compono/ui';
import { theme as UITheme } from '@compono/ui-theme-compono';
import { AnalyticsProvider } from '@compono/analytics';
import mixpanelPlugin from '@analytics/mixpanel';
import { ENVIRONMENT, HEADLESS_TESTING, MIXPANEL_TEST_TOKEN, MIXPANEL_PROD_TOKEN } from 'lib/env';
import Routes from './routes';
import theme from './styles/theme';

const isProduction = ENVIRONMENT === 'prod';

let plugins = [];
if (isProduction && !HEADLESS_TESTING) {
  plugins = [
    ...plugins,
    mixpanelPlugin({
      token: isProduction ? MIXPANEL_PROD_TOKEN : MIXPANEL_TEST_TOKEN,
    }),
  ];
}
export default () => (
  <AnalyticsProvider appName="candidate" plugins={plugins}>
    <UIProvider theme={UITheme}>
      <ThemeProvider theme={{ ...tokens, ...theme }}>
        <Auth0Wrapper>
          <AuthorizedApolloProvider>
            <FakeLegacyApolloContext>
              <GlobalStyles />
              <AuthFetcher>
                <Sentry>
                  <Routes />
                </Sentry>
              </AuthFetcher>
            </FakeLegacyApolloContext>
          </AuthorizedApolloProvider>
        </Auth0Wrapper>
      </ThemeProvider>
    </UIProvider>
  </AnalyticsProvider>
);
