import styled from 'styled-components';

const StyledSpinner = styled.div`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  text-indent: -999em;
  width: 1em;
  height: 1em;
  border: 0.1em solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  border-top-color: rgba(0, 0, 0, 0.5);
  animation: spin 1s ease-in-out infinite;
`;

export default StyledSpinner;
