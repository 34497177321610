import styled from 'styled-components';
import { color } from '@shortlyster/ui-kit';
import { Layout, LabelBlock, InputBlock, ErrorBlock, HelpBlock } from '@shortlyster/forms-kit';
import CheckboxTickIcon from 'styles/icons/checkbox-tick.svg';

export default styled(Layout)`
  /* vertically stacked layout */
  display: block;
  max-width: none;

  ${LabelBlock}, ${InputBlock}, ${ErrorBlock} {
    margin: 0;
    padding: 0;
    display: block;
    max-width: none;
  }

  ${LabelBlock} {
    line-height: inherit;
    margin-bottom: ${p => p.theme.spacingFormLabel};
    color: ${color('black')};
  }

  ${HelpBlock} {
    opacity: 1;
    display: block;
    padding-top: 0.5em;
    margin-top: 0;
    position: relative;
  }

  ${ErrorBlock} {
    font-size: 100%;
    margin-top: ${p => p.theme.spacingFormLabel};
    color: ${color('statusCritical')};
  }

  :-ms-input-placeholder {
    color: #ccc !important;
    font-weight: 400 !important;
}

  /* generic inputs */
  input[type='tel'],
  input[type='text'],
  input[type='email'],
  input[type='search'],
  input[type='number'],
  input[type='password'],
  input[type='radio'],
  input[type='checkbox'],
  textarea,
  select,
  .selecty {
    border: 1px solid ${color('dark-1')};

    &:focus {
      background: ${color('focus')};
    }

    &:disabled {
      border: none;
      background: ${color('statusDisabled')};
    }
  }

  .rte-container {
    border-radius: 4px;
    box-shadow: none;
    color: ${({ theme }) => theme.global.colors.black};
    border: thin solid ${({ theme }) => theme.global.colors['dark-1']};

    .rdw-editor-toolbar {
      background: ${({ theme }) => theme.global.colors.white};
      border: none;
      border-bottom: thin solid ${({ theme }) => theme.global.colors['dark-1']};
      border-radius: 4px 4px 0 0;
    }
    .rdw-list-wrapper {
      margin-bottom: 6px;
    }
  }
  .rdw-editor-main {
    border-radius: 0 0 4px 4px;
  }
  .public-DraftEditor-content {
    min-height: 7rem;
  }
  .public-DraftEditor-content:focus::before {
    content: '';
    display: block;
    position: absolute;
    background: #edf6fb;
    z-index: 0;
    left: -1rem;
    top: -1rem;
    bottom: 0;
    width: calc(100% + 2rem);
    height: calc(100% + 1rem);
  }

  [data-disabled='true'] {
    opacity: 1;
  }

  [data-disabled='true'] .rte-container {
    background: ${({ theme }) => theme.global.colors['dark-3']};

    .rdw-editor-toolbar,
    .rdw-editor-main,
    .rdw-option-wrapper {
      background: ${({ theme }) => theme.global.colors['dark-3']};
      border: 1px solid ${({ theme }) => theme.global.colors['dark-3']};
    }
    .rdw-editor-toolbar {
      border-bottom-color: ${({ theme }) => theme.global.colors['dark-1']};
    }
  }

  /* placeholders (https://caniuse.com/#feat=input-placeholder) */
  input::placeholder {
    color: ${color('dark-1')};
  }

  input:-ms-input-placeholder {
    color: ${color('dark-1')} !important;
  }

  .Select-placeholder {
    color: ${color('dark-1')};
    opacity: 1;
  }

  /* radios and checkboxes */
  input[type='radio'],
  input[type='checkbox'] {
    appearance: none;
    position: relative;
    outline: none;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0;
    background: ${color('white')};
  }

  input[type='radio'],
  input[type='checkbox'],
  input[type='radio'] + *,
  input[type='checkbox'] + * {
    cursor: pointer;
  }

  input[type='radio'] {
    border-radius: 50%;

    &:not(:checked) {
      background: ${color('white')};

      &:focus {
        background: ${color('focus')};
      }
    }

    &:checked::before {
      content: '';
      position: absolute;
      border-radius: 50%;
      left: 10%;
      right: 10%;
      top: 10%;
      bottom: 10%;
      background: ${color('brand')};
    }
  }

  input[type='checkbox'] {
    &:not(:checked) {
      background: ${color('white')};

      &:focus {
        background: ${color('focus')};
      }
    }

    &:checked::before {
      content: '';
      position: absolute;
      left: 10%;
      right: 10%;
      top: 10%;
      bottom: 10%;
      background: url(${CheckboxTickIcon}) no-repeat;
      background-size: contain;
    }
  }

  /* errors and such */
  [data-invalid='true'] {
    input[type='tel'],
    input[type='text'],
    input[type='email'],
    input[type='search'],
    input[type='number'],
    input[type='password'],
    textarea,
    select,
    .selecty,
    button[class^='Select__StyledSelectDropButton'],
    input[type='radio'],
    input[type='checkbox'] {
      border-color: ${color('statusCritical')};
      background: ${color('statusInvalid')} !important;
      color: ${color('statusCritical')};
    }
  }

  /* selects and dropdowns */
  .Select {
    align-items: center;
    display: flex;

    &.is-focused {
      background: ${color('focus')};
    }

    .Select-input {
      line-height: 1rem !important;
    }

    &.Select--multi {
      .Select-value {
        border-radius: 3px;
        background-color: ${color('brand')};
      }

      & > .Select-control {
        line-height: 1em !important;
      }

      .Select-value-label {
        color: ${color('white')};
      }

      .Select-value-icon {
        width: 20px;
        height: 20px;
        line-height: 1.05;
        font-size: 1.2rem;
        font-weight: bold;
        border-radius: 50%;
        text-indent: 1px;
        color: ${color('white')};
        opacity: 1;
        padding: 0;
        text-align: center;
        margin: 0.4rem;
        transform: rotate(0);
      }

      .Select-value-icon:hover {
        color: ${color('statusCritical')};
      }
    }

    > .Select-control span.Select-arrow {
      border: solid 2.3px ${color('primary')};
      transform: rotate(45deg);
      border-left: none;
      border-top: none;
      margin-top: -3px;
      display: block;
      height: 11px;
      width: 11px;
    }

    &.is-open > .Select-control span.Select-arrow {
      transform: rotate(-135deg);
      margin-top: 8px;
    }

    & .selecty {
      margin-bottom: -1px;
    }
  }
`;
