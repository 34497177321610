import { Component } from 'react';
import { field, InputProps } from 'a-plus-forms';
import { listTimeZones } from 'timezone-support';

import { EmptyLayout, AsyncSelectSingle, AsyncSelectSingleProps } from '@shortlyster/forms-kit';

export const timezoneToOption = (timezone: string) => ({ label: timezone, value: timezone });

const timezoneList = listTimeZones();
const timezones = timezoneList.map(timezoneToOption);

class FormattedTimezoneSelect extends Component<AsyncSelectSingleProps & InputProps> {
  render() {
    const searchTzOptions = (search: string) => {
      const regex = new RegExp(search, 'gi');
      const matches = timezoneList.filter(timezone => regex.test(timezone)).map(timezoneToOption);
      return Promise.resolve(matches);
    };

    const rehydrateOptions = (search: string) => {
      const regex = new RegExp(search, 'gi');
      const [match] = timezoneList.filter(timezone => regex.test(timezone)).map(timezoneToOption);
      return Promise.resolve(match);
    };

    return (
      <AsyncSelectSingle
        {...this.props}
        layout={EmptyLayout}
        rehydrateOptions={rehydrateOptions}
        fetchOptions={searchTzOptions}
        options={timezones}
      />
    );
  }
}

export default field<Omit<AsyncSelectSingleProps, 'fetchOptions' | 'rehydrateOptions'>>()(
  FormattedTimezoneSelect
);
