import { QualificationSelect } from '@shortlyster/quals-kit';
import { field, InputProps } from 'a-plus-forms';
import { AsyncSelectSingleProps, EmptyLayout } from '@shortlyster/forms-kit';
import { Component } from 'react';
import styled from 'styled-components';

const StyledQualificationSelect = styled(QualificationSelect)`
  input {
    text-overflow: ellipsis;
  }
`;

class QualSelect extends Component<AsyncSelectSingleProps & InputProps> {
  render() {
    return (
      <StyledQualificationSelect
        {...this.props}
        layout={EmptyLayout}
        noResultsMessage="No matching results"
      />
    );
  }
}

export default field<AsyncSelectSingleProps>()(QualSelect);
