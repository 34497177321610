import { Component } from 'react';
import { getApolloContext } from '@apollo/client';
import { AsyncSelectSingle, AsyncSelectSingleProps, EmptyLayout } from '@shortlyster/forms-kit';
import gql from 'graphql-tag';
import { field, InputProps } from 'a-plus-forms';

export const searchInstitutionsQuery = gql`
  query SearchInstitutions($search: String!) {
    institutions(search: $search, page: 1) {
      id
      name
    }
  }
`;

export const getInstitutionQuery = gql`
  query GetInstitution($id: ID!) {
    institution(id: $id) {
      id
      name
    }
  }
`;

const institutionToOption = ({ id, name }) => ({ value: id, label: name });

class InstitutionsSelect extends Component<AsyncSelectSingleProps & InputProps> {
  static contextType = getApolloContext();

  rehydrateOptions = id => {
    const { client } = this.context;
    return client
      .query({ query: getInstitutionQuery, variables: { id } })
      .then(({ data: { institution } }) => institutionToOption(institution));
  };

  fetchOptions = search => {
    const { client } = this.context;
    return client
      .query({
        query: searchInstitutionsQuery,
        variables: { search },
      })
      .then(({ data: { institutions = [] } }) => institutions.map(institutionToOption));
  };

  render() {
    return (
      <AsyncSelectSingle
        {...this.props}
        layout={EmptyLayout}
        rehydrateOptions={this.rehydrateOptions}
        fetchOptions={this.fetchOptions}
        noResultsMessage="No matching results"
      />
    );
  }
}

export default field<AsyncSelectSingleProps>()(InstitutionsSelect);
