import styled from 'styled-components';
import { field, InputProps, InputEvent } from 'a-plus-forms';
import { color } from '@shortlyster/ui-kit';
import React from 'react';

type Option = {
  label: string;
  value: string;
  disabled?: boolean;
};

type RadiosProps = InputProps & {
  options?: Option[];
};

let uniqueNumber = 0;

@field()
class Radios extends React.Component<RadiosProps> {
  state = { unique: uniqueNumber++ };

  onChange = (event: InputEvent) => {
    this.props.onChange(event.target.value);
  };

  render() {
    const { value: currentValue, options = [], name } = this.props;
    const { unique } = this.state;

    return (
      <>
        {options.map(({ label, value, disabled }, i) => (
          <label key={i} disabled={disabled}>
            <input
              type="radio"
              name={`${unique}-${name}`}
              value={value}
              onChange={this.onChange}
              checked={value === currentValue}
            />
            <span>{label}</span>
          </label>
        ))}
      </>
    );
  }
}

const SegmentedControl = styled(Radios)`
  margin: 1rem 0;
  text-align: center;

  && > label {
    text-align: center;
    max-width: 32rem;
    margin: 0 auto;
    padding: 0.5rem 0 1.5rem 0;
    color: ${color('black')};
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    margin: 0.5rem 0;
  }

  input[type='radio'] {
    position: absolute;
    left: -10000px;
    opacity: 0;

    &:hover + span,
    &:focus + span {
      box-shadow: 0 0 5px ${color('primary')};
      z-index: 1;
    }
  }

  span {
    display: block;
    position: relative;
    border: 2px solid ${color('primary')};
    padding: 2rem 1rem;
    whitespace: nowrap;
    margin-left: 0 !important; /* override margin from "label + *" */
    cursor: pointer;

    @media (${p => p.theme.phoneUpperBoundary}) {
      padding: 1rem 0;
      width: 50%;
      white-space: nowrap;
    }

    @media (${p => p.theme.tabletPortraitAndUp}) {
      min-width: 10rem;
    }

    &:hover,
    &:focus {
      box-shadow: 0 0 5px ${color('primary')};
      z-index: 1;
    }
  }

  label:first-child span {
    border-radius: 0.5rem 0 0 0.5rem;
  }

  label:not(:last-child) span {
    margin-right: -2px;
  }

  label:last-child span {
    border-radius: 0 0.5rem 0.5rem 0;
  }

  input:checked + span {
    background-color: ${color('primary10')};
  }
`;

export default SegmentedControl;
