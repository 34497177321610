import gql from 'graphql-tag';
import { Location } from 'features/types';

type Relocation = {
  country: string;
  state?: string;
  city?: string;
  authorizedForWork: boolean;
};

export type AuthCandidate = {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  employmentTypes?: Array<'full-time' | 'part-time' | 'contract' | 'casual'>;
  willRelocateTo?: Relocation[];
  location?: Location;
  salaryExpectation?: {
    min: number;
    max: number;
  };
};

export type FetchCandidateProfileResult = {
  candidateProfile: {
    id: string;
    details: AuthCandidate;
  };
};

/* NOTE: must include everything in src/lib/auth/queries */
export const FetchCandidateProfile = gql`
  query FetchCandidateProfile {
    profile {
      id
      timezone
      emailVerified
    }
    candidateProfile {
      id
      details {
        firstName
        lastName
        email
        phone
        employmentTypes
        willRelocateTo {
          authorizedForWork
          country
          state
          city
        }
        location {
          authorizedForWork
          country
          state
          city
        }
        salaryExpectation {
          min
          max
        }
      }
    }
  }
`;

export type UpdateCandidateDetailsResult = {
  updateCandidateDetails: {
    id: string;
    details: AuthCandidate;
  };
};

export const UpdateCandidateDetails = gql`
  mutation UpdateCandidateDetails($details: CandidateDetailsInput!) {
    updateCandidateDetails(details: $details) {
      id
      details {
        firstName
        lastName
        email
        phone
        employmentTypes
        willRelocateTo {
          authorizedForWork
          country
          state
          city
        }
        location {
          authorizedForWork
          country
          state
          city
        }
        salaryExpectation {
          min
          max
        }
      }
    }
  }
`;

export type initCandidateProfileResult = {
  initCandidateProfile: {
    details: AuthCandidate & { jobs?: [string]; education?: [string] };
  };
};

export const InitCandidateProfile = gql`
  mutation InitCandidateProfile($details: CandidateInitDetailsInput!) {
    initCandidateProfile(details: $details) {
      id
      details {
        firstName
        lastName
        email
        phone
        employmentTypes
        willRelocateTo {
          authorizedForWork
          country
          state
          city
        }
        location {
          authorizedForWork
          country
          state
          city
        }
        salaryExpectation {
          min
          max
        }
      }
    }
  }
`;

export const SetTimezone = gql`
  mutation SetTimezone($timezone: String!) {
    setTimezone(timezone: $timezone) {
      id
      timezone
    }
  }
`;

export const FetchLocationSuggestions = gql`
  query FetchLocationSuggestions($input: String!) {
    result: autoSuggestLocations(input: $input) {
      locations {
        label
        value
        placeId
      }
    }
  }
`;

export const ResolveLocation = gql`
  query ResolveLocation($input: String!) {
    result: resolveLocation(address: $input) {
      placeId
      location {
        lat
        lng
      }
      names {
        city
        state
        country
      }
    }
  }
`;

export const ResolveLocationId = gql`
  query ResolveLocation($input: String!) {
    result: resolveLocation(placeId: $input) {
      placeId
      location {
        lat
        lng
      }
      names {
        city
        state
        country
      }
    }
  }
`;
