import { SelectWorkArea } from '../../../skills-kit';
import { field, InputProps } from 'a-plus-forms';
import { EmptyLayout, AsyncSelectMultiProps } from '@shortlyster/forms-kit';
import { Component } from 'react';

class FormattedSelectWorkArea extends Component<AsyncSelectMultiProps & InputProps> {
  render() {
    return <SelectWorkArea {...this.props} layout={EmptyLayout} />;
  }
}

export default field<AsyncSelectMultiProps>()(FormattedSelectWorkArea);
