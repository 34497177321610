import { MayersBrigsLetter } from '@shortlyster/assessments-kit';
import SegmentedControl from './SegmentedControl';

const likeMeOptions = (options: MayersBrigsLetter[]) => {
  const [yesValue, noValue] = options;
  return [
    { label: 'Mostly unlike me', value: noValue },
    { label: 'Mostly like me', value: yesValue },
  ];
};

export interface Props {
  options: MayersBrigsLetter[] | string[];
  value?: MayersBrigsLetter | string;
  onChange?: (value: MayersBrigsLetter | string) => void;
}

const LikeMe = ({ options, labels, ...props }: Props) => {
  const [label] = labels;
  return <SegmentedControl {...props} label={`"${label}"`} options={likeMeOptions(options)} />;
};

export default LikeMe;
