import styled from 'styled-components';
import { Checkbox, LabelBlock, InputBlock } from '@shortlyster/forms-kit';

const CheckboxLabelAligned = styled(Checkbox)`
  && ${LabelBlock} {
    display: none;
  }

  ${InputBlock} label {
    align-items: center;
    display: flex;
    line-height: inherit;
    margin-top: 0;
  }

  ${InputBlock} span {
    flex: 1;
    margin-left: calc(${p => p.theme.spacingFormLayout} / 2);
  }
`;

export default CheckboxLabelAligned;
