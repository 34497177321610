import styled from 'styled-components';
import { Slider } from '@shortlyster/forms-kit';
import { color } from '@shortlyster/ui-kit';

Slider.Rail = styled(Slider.Rail)`
  background-color: ${color('white')};
  border: 2px solid ${color('primary')};
`;

Slider.Handle = styled(Slider.Handle)`
  background-color: ${color('primary100')};

  &:focus {
    outline: none;
    box-shadow: 0 0 10px ${color('primary100')};
  }

  ${Slider.Handle.Value} {
    display: none;
  }
`;

Slider.Track = styled(Slider.Track)`
  background-color: ${color('white')};
  border: 2px solid ${color('primary')};
  border-radius: ${({ theme }) => theme.slider.trackHeight / 2}rem;
`;

export default Slider;
