import styled from 'styled-components';
import { LabelBlock } from '@shortlyster/forms-kit';
import { Answer } from '@shortlyster/assessments-kit';
import { capitalize } from 'src/lib/utils';
import BaseSegmentedControl from './SegmentedControl';

const SegmentedControl = styled(BaseSegmentedControl)`
  ${LabelBlock} {
    display: none !important;
  }
`;

type FormatOptions = {
  labels: string[];
  options: Answer[];
};

const formatOptions = ({ labels, options }: FormatOptions) => {
  const [leftLabel = '', rightLabel = ''] = labels;
  const [leftValue = '', rightValue = ''] = options;

  return [
    { label: capitalize(leftLabel), value: leftValue as string },
    { label: capitalize(rightLabel), value: rightValue as string },
  ];
};

export interface Props {
  labels?: string[];
  options: Answer[];
  value?: Answer;
  onChange?: (value: Answer) => void;
}

const EitherOr = ({ options, labels, ...props }: Props) => (
  <SegmentedControl {...props} options={formatOptions({ options, labels })} />
);

export default EitherOr;
