import { Component } from 'react';
import styled from 'styled-components';
import { field } from 'a-plus-forms';
import { SelectSingle } from '@shortlyster/forms-kit';

const SALARY = {
  MAX: 300000,
  MIN: 30000,
  INTERVAL: 5000,
};
const BLOATED_SALARY = {
  MIN: 350000,
  MAX: 1000000,
  INTERVAL: 50000,
};

const length = (SALARY.MAX - SALARY.MIN) / SALARY.INTERVAL + 1;
const normalSalaries = Array.from({ length }, (_, i) => i * SALARY.INTERVAL + SALARY.MIN);
const bloatedLength = (BLOATED_SALARY.MAX - BLOATED_SALARY.MIN) / BLOATED_SALARY.INTERVAL + 1;
const bloatedSalaries = Array.from(
  { length: bloatedLength },
  (_, i) => i * BLOATED_SALARY.INTERVAL + BLOATED_SALARY.MIN
);

const salaries = normalSalaries.concat(bloatedSalaries);

const addCommas = (n: number): string => n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const labelSalaries = items =>
  items.map(value => ({
    value,
    label: `$${addCommas(value)}`,
  }));

/**
 * Get the maximum salary option based off specific business logic:
 * - The maximum salary should be the minimum salary + 50% of the minimum salary
 * @param {number} minSalary - the selected `min` salary option's value.
 */
const getMaxSalaryOption = minSalary => {
  if (!minSalary) return salaries[salaries.length - 1];

  const maxSalary = minSalary * 1.5;

  // we can't just use Math.min here because maxSalary might not be an actual option
  return salaries.reduce((result, salary) => (salary <= maxSalary ? salary : result), Infinity);
};

/**
 * Get a list of 'max' select options.
 * @param {number} minSalary - the selected `min` salary option's value.
 */
const getMaxSalaryOptions = minSalary => {
  if (!minSalary) return salaries;

  const maxSalaryOption = getMaxSalaryOption(minSalary);

  const salaryRange = salaries.slice(
    salaries.indexOf(minSalary),
    salaries.indexOf(maxSalaryOption) + 1
  );

  return salaryRange;
};

/**
 * Returns whether a maximum salary is valid with the passed minimum salary
 * @param {number} maxSalary - a potiential max salary.
 * @param {number} minSalary - the selected `min` salary option's value.
 */
const isValidMaxSalary = (maxSalary, minSalary) =>
  minSalary && maxSalary > minSalary && maxSalary <= getMaxSalaryOption(minSalary);

const SelectGroup = styled.div`
  display: flex;
  flex-direction: column;

  @media (${p => p.theme.tabletPortraitAndUp}) {
    flex-direction: row;
  }
`;

const PrettySelect = styled(SelectSingle)`
  margin: 0;
  display: inline-flex;
  width: 100%;

  label {
    text-align: center;
  }

  @media (${p => p.theme.tabletPortraitAndUp}) {
    margin: 0 20px;

    &:first-child {
      margin: 0 20px 0 0;
    }

    &:last-child {
      margin: 0 0 0 20px;
    }
  }
`;

const Label = styled.label`
  align-self: center;
  margin: 0 auto;
`;

type Props = {
  onChange: Function;
  value?: {
    min?: string;
    max?: string;
  };
};

class SalarySelect extends Component<Props> {
  handleMinChange = (newMinSalary: number) => {
    const { onChange, value: { max } = { max: undefined } } = this.props;

    const newMaxSalary = isValidMaxSalary(max, newMinSalary) ? max : undefined;

    onChange({
      min: newMinSalary,
      max: newMaxSalary,
    });
  };

  handleMaxChange = (newMaxSalary: number) => {
    const { onChange, value: { min } = { min: undefined } } = this.props;

    onChange({
      min,
      max: newMaxSalary,
    });
  };

  render() {
    const { value: { min, max } = { min: undefined, max: undefined } } = this.props;

    return (
      <SelectGroup>
        <PrettySelect
          placeholder="Min"
          layout={null}
          value={min}
          onChange={this.handleMinChange}
          options={labelSalaries(salaries)}
        />
        <Label>to</Label>
        <PrettySelect
          placeholder="Max"
          layout={null}
          key={max}
          value={max}
          onChange={this.handleMaxChange}
          options={labelSalaries(getMaxSalaryOptions(min))}
        />
      </SelectGroup>
    );
  }
}

export default field()(SalarySelect);
