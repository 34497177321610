import {
  locationResolveOutput,
  locationSelectionOption,
} from '@shortlyster/forms-kit/dist/inputs/locationSelectTypes';
import {
  FetchLocationSuggestions,
  ResolveLocation,
  ResolveLocationId,
} from 'features/profile/details/queries';

/**
 * Query for a list of location suggestions based on the input string.
 *
 * @param input The location input string
 *
 * FUTURE: The query can also take a `scope` parameter to limit to city, state or country results.
 * By default a scope of `city` is applied internally
 */
export const getLocationSuggestions =
  client =>
  async ({ input }): Promise<locationSelectionOption[]> => {
    const qryResp = await client.query({
      query: FetchLocationSuggestions,
      variables: { input },
    });

    const { data = {}, error } = qryResp;
    if (error) throw error;

    const { result } = data;
    return result.locations.map(({ label, placeId }) => ({
      label,
      placeId,
      value: Object.assign(label, { placeId }), // Encode (hack) placeId into the value
    }));
  };

export const getResolvedLocation =
  client =>
  async ({ address, placeId }): Promise<locationResolveOutput> => {
    // Note: future implementation may use placeId as a parameter to the query
    const qryResp = await client.query({
      query: placeId ? ResolveLocationId : ResolveLocation,
      variables: { input: placeId || address },
    });

    const { data = {}, error } = qryResp;
    if (error) throw error;

    const { result } = data;
    if (!result) return null;

    const { city, state, country, placeId: resolvedPlaceId, location } = result.names;
    return {
      placeId: resolvedPlaceId,
      location,
      names: {
        country,
        ...(state && { state }),
        ...(city && { city }),
      },
    };
  };
