import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Locker } from 'features/various';
import history from 'lib/history';
import { User } from 'lib/auth';
import { useUser } from '@shortlyster/auth-layer';
import { GenerateVerificationUrl } from './queries';

const verifyUser = async (token, redirectUrl, setUserVerified) => {
  const {
    data: {
      generateVerificationUrl: { ticket },
    },
  } = await setUserVerified({ variables: { token, redirectUrl } });

  window.sessionStorage.removeItem('token');
  window.sessionStorage.removeItem('redirect');

  if (ticket) {
    history.changeDomainTo(ticket);
  } else {
    history.replace('/');
  }
};

export const AutoVerify = ({ token, redirectTo }) => {
  const [user] = useUser<User>();

  if (user.emailVerified) {
    window.sessionStorage.removeItem('token');
    window.sessionStorage.removeItem('redirect');
    useEffect(() => {
      if (redirectTo) {
        history.replace(redirectTo);
      } else {
        history.replace('/');
      }
    });
    return <Locker />;
  }

  const [generateVerificationUrl, { loading, called }] = useMutation(GenerateVerificationUrl);

  useEffect(() => {
    const fullRedirectUrl = `${window.location.origin}${redirectTo}`;
    verifyUser(token, fullRedirectUrl, generateVerificationUrl);
  }, []);

  if (loading || !called) {
    return <Locker />;
  }

  return <></>;
};
