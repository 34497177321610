import { ApolloError } from 'apollo-client'; // eslint-disable-line

// converts an apollo error into a human readable string
export const formatApolloError = (error: ApolloError) => {
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    return error.graphQLErrors.map((e: any) => e.message).join(". ");
  } else if (error.networkError) {
    const { result: { errors = [] } = {} } = error.networkError as any;
    return errors.map((e: any) => e.message).join(" ");
  } else if (error.message) {
    return error.message;
  }

  return undefined;
};
