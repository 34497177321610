/**
 * Put your colors here to override the global theme
 */
const colors = {};

const fontSize = 18;

export default {
  colors,

  phoneUpperBoundary: 'max-width: 599px',
  tabletPortraitAndUp: 'min-width: 600px',
  tabletLandscapeAndUp: 'min-width: 900px',
  desktopAndUp: 'min-width: 1200px',
  spacingFormLayout: '1rem',
  spacingFormLabel: '0.25rem',
  slider: {
    sliderHeight: 40 / fontSize,
    trackHeight: 24 / fontSize,
    handleWidth: 40 / fontSize,
    handleHeight: 40 / fontSize,
    handleSize: 40 / fontSize,
    tickHeight: 6 / fontSize,
    fontSize: 10 / fontSize,
  },
};
