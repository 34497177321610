import styled from 'styled-components';

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 1rem;

  @media (${p => p.theme.tabletPortraitAndUp}) {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    align-items: stretch;
    justify-content: stretch;

    margin: 0 -0.5rem;

    & > div {
      max-width: calc(50% - 1rem);
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      margin-bottom: 1rem;
    }
  }
`;

export default Grid;
