import React from 'react';
import styled from 'styled-components';
import { field, InputProps } from 'a-plus-forms';
import {
  LabelBlock,
  LocationServiceSelect as LocationSelect,
  LocationServiceSelectProps as LocationSelectProps,
} from '@shortlyster/forms-kit';

import { getLocationSuggestions, getResolvedLocation } from 'lib/forms/LocationPickerUtil';
import { apolloClientWithAuth0 } from 'src/lib/graphql';
import CheckboxLabelAligned from './CheckboxLabelAligned';

const locationMatch = (loc1: Location) => (loc2: Location) =>
  loc1.country === loc2.country && loc1.state === loc2.state && loc1.city === loc2.city;

// @ts-ignore
const SmolCheckbox = styled(CheckboxLabelAligned)`
  flex-basis: 55%;
`;
const CheckWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${SmolCheckbox} {
    margin-top: 0.5rem;
  }
  ${LabelBlock} {
    display: none;
  }
`;

type Location = {
  authorizedForWork?: boolean;
  country: string;
  state?: string;
  city?: string;
};

export interface Props extends InputProps {
  onChange?: (value: Location[]) => void;
  value?: Location[];
  accepts?: LocationSelectProps['accepts'];
  placeholder?: string;
  noResultsMessage?: string;
}

const RelocationPicker: React.FC<Props> = props => {
  const { value: locations = [], onChange: onChangeProp, accepts, noResultsMessage } = props;

  const client = apolloClientWithAuth0();

  const onChange = (inputLocations: Location[]) => {
    if (locations && inputLocations.length > locations.length) {
      // return the new location no matter where it's placed
      const newestLocation = inputLocations.reduce<Location>(
        (newLocation, location) => (locations.find(e => e === location) ? newLocation : location),
        {}
      );

      // recast the updated locations without that latest addition
      const updatedLocations = [...inputLocations].filter(e => e !== newestLocation);

      // if location is a duplicate fire onChange with old value to prevent duplicate pill render
      const isDuplicate = !!updatedLocations.find(locationMatch(newestLocation));
      if (isDuplicate) return onChangeProp(updatedLocations);

      // determine authforwork, if it's the same as any prev. matching country
      const sameAsPreviousCountry = locations.find(
        (e: Location) => e.country === newestLocation.country
      );
      const authorizedForWork = !!(
        sameAsPreviousCountry && sameAsPreviousCountry.authorizedForWork
      );

      // reset new location with proper authorizedForWork
      return onChangeProp([...updatedLocations, { ...newestLocation, authorizedForWork }]);
    }

    return onChangeProp(inputLocations);
  };

  const onVisaSwitchClick = (country: string) => {
    // remap all matching country values to their !authorizedForWork state
    const news = locations.map(location => {
      if (location.country === country) {
        return { ...location, authorizedForWork: !location.authorizedForWork };
      }
      return location;
    });

    return onChange(news);
  };

  const countryLocations = (locations || []).reduce((countries, location) => {
    const duplicate = countries.find((e: Location) => e.country === location.country);
    return duplicate ? countries : [...countries, location];
  }, []);

  return (
    <div>
      <LocationSelect
        value={locations}
        onChange={onChange}
        layout={null}
        accepts={accepts}
        multiple
        noResultsMessage={noResultsMessage}
        onResolve={getResolvedLocation(client)}
        onSuggest={getLocationSuggestions(client)}
      />
      <CheckWrapper>
        {countryLocations.map(location => (
          <SmolCheckbox
            key={location.country}
            value={location.authorizedForWork}
            onChange={() => onVisaSwitchClick(location.country)}
            label={`I require sponsorship to work in ${location.country}`}
          />
        ))}
      </CheckWrapper>
    </div>
  );
};

export default field<Props>()(RelocationPicker);
