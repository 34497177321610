import { createGlobalStyle } from 'styled-components';
import { Form } from '@shortlyster/forms-kit';
import Spinner from '../Spinner';

// A+ forms buttons auto-disable
export const DisabledFormStyles = createGlobalStyle`
  form[disabled] button[type="submit"] {
    opacity: 0.7;
    pointer-events: none;
    cursor: default !important;

    ${Spinner} {
      pointer-events: none;
      display: inline-block;
    }
  }
`;

export default Form;
