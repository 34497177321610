import { Option, SkillField, WorkArea } from "./SelectWorkArea";

const options = [];
function memoizeOption(option: Option): Option {
  if (!options[option.value]) {
    options[option.value] = option;
  }
  return options[option.value];
}

export const memoizeWorkArea = (
  workArea: WorkArea,
  skillField: SkillField
): Option =>
  memoizeOption({
    value: workArea.id,
    label: workArea.name,
    type: "workArea",
    fieldId: skillField.id,
    fieldLabel: skillField.name,
  });

export const memoizeSkillField = (skillField: SkillField): Option =>
  memoizeOption({
    value: skillField.id,
    label: skillField.name,
    type: "skillField",
    workAreas: skillField.workAreas.map((wa) =>
      memoizeWorkArea(wa, skillField)
    ),
  });
