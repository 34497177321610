/**
 * A bunch of deps (quals-kit, skills-kit) reach out into
 * old apollo context that was using the old context API
 * so, i'm making a thing to keep them operating until we
 * fully transition them to the new apollo stuff
 *
 * __NOTE__ make sure this one goes on the _inside_ of the
 * original ApolloProvider
 */
import React from 'react';
import PropTypes from 'prop-types';
import { getApolloContext } from '@apollo/client';

export default class FakeLegacyApolloContext extends React.Component {
  static childContextTypes = {
    client: PropTypes.object,
  };

  static contextType = getApolloContext();

  getChildContext() {
    const { client } = this.context;
    return { client };
  }

  render() {
    return this.props.children;
  }
}
