import { FC, useState } from 'react';
import styled from 'styled-components';
import { A } from '@shortlyster/ui-kit';
import JobDetailsModal from 'features/various/JobDetails/JobDetailsModal';
import { Listing } from './types';

type Props = {
  listing: Listing;
  openModal?: boolean;
  onClose?: () => void;
  logoURL?: string;
};

const DetailsLink = styled(A)`
  display: block;
  width: fit-content;
  cursor: pointer;
`;

const JobDetailsLink: FC<Props> = ({ listing, openModal = false, onClose }) => {
  const [open, setOpen] = useState<boolean>(openModal);
  const onClick = () => {
    setOpen(false);
    onClose && onClose();
  };

  return (
    <>
      <DetailsLink onClick={() => setOpen(!open)} data-testid="view-full-job-details-button">
        View full job details
      </DetailsLink>
      <JobDetailsModal listing={listing} open={open} onClose={onClick} />
    </>
  );
};

export default JobDetailsLink;
