import { useAuth0 } from '@auth0/auth0-react';

import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from 'apollo-upload-client';

import { apolloSpy } from '@shortlyster/analytics-kit';
import { getRequestId } from 'lib/Sentry';

import { watcher } from './mixpanel';
import { API_URL } from './env';
import customFetch from './customFetch';

export const apolloClientWithAuth0 = () => {
  const uri = `${API_URL}/graphql`;
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const authLink = setContext(async (_, { headers }) => {
    if (isAuthenticated) {
      const accessToken = await getAccessTokenSilently();
      if (accessToken) {
        return {
          headers: {
            ...headers,
            authorization: `Bearer ${accessToken}`,
            'x-request-id': getRequestId(),
          },
        };
      }
    }
    return { headers };
  });

  const baseClient = new ApolloClient({
    link: authLink.concat(createUploadLink({ uri, fetch: customFetch })),
    cache: new InMemoryCache(),
  });
  apolloSpy.configure({ watcher });

  return apolloSpy.patch(baseClient);
};
