import React, { useContext } from 'react';
import MediaQuery from 'react-responsive';
import styled, { ThemeContext } from 'styled-components';
import {
  ApplicationIcon,
  ButtonMenuItem,
  breakpoint,
  LogoMenuItem,
  LogoutIcon,
  MenuItem,
  NavBar,
  Spacer,
  UserIcon,
  CalendarIcon,
  Link,
} from '@shortlyster/ui-kit';
import { Button, ComponoLogoMark } from '@compono/ui';
import { ComponoLogo } from 'src/features/various/ComponoLogoDarkBg';

import MessagesMenuItem from 'features/messages/MessagesMenuItem';
import history from 'lib/history';
import appAccessValidation from 'src/lib/auth/appAccessValidation';

type Props = {
  pollInterval?: number;
};

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Navigation: React.FC<Props> = ({ pollInterval }) => {
  const { pathname } = history.location;
  const theme = useContext(ThemeContext);

  const isCandidateUser = appAccessValidation();

  const isApplications = ['/active', '/closed'].includes(pathname);
  const isMessages = /^\/messages\/?/.test(pathname);
  const isInterviews = /^\/interviews\/?/.test(pathname);
  const isProfile = pathname === '/profile';

  const onSignOut = () => {
    history.push('/signout');
  };

  return (
    <NavBar>
      <LogoMenuItem
        link={Link}
        desktop={ComponoLogo}
        mobile={ComponoLogoMark}
        to="/profile"
        data-testid="profile-logo-link"
      />
      <MenuItem
        icon={UserIcon}
        link={Link}
        to="/profile"
        current={isProfile}
        data-testid="profile-menu-link"
      >
        Profile
      </MenuItem>
      {isCandidateUser && (
        <>
          <MenuItem
            icon={ApplicationIcon}
            link={Link}
            to="/active"
            current={isApplications}
            data-testid="applications-menu-link"
          >
            Applications
          </MenuItem>
          <MessagesMenuItem current={isMessages} pollInterval={pollInterval} />
          <MenuItem
            icon={CalendarIcon}
            link={Link}
            to="/interviews"
            current={isInterviews}
            data-testid="interviews-menu-link"
          >
            Interviews
          </MenuItem>
        </>
      )}
      <Spacer noSeparator={false} />

      <MediaQuery query={breakpoint('minTablet')({ theme })}>
        {isDesktop =>
          isDesktop ? (
            <ButtonWrapper>
              <Button
                iconLeft={LogoutIcon}
                look="tertiary"
                onClick={onSignOut}
                data-testid="logout-button"
              >
                Log Out
              </Button>
            </ButtonWrapper>
          ) : (
            <ButtonMenuItem
              noBorder
              icon={LogoutIcon}
              onClick={onSignOut}
              data-testid="logout-button"
            />
          )
        }
      </MediaQuery>
      <Spacer noSeparator width="12px" />
    </NavBar>
  );
};

export default Navigation;
