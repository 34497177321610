import styled from 'styled-components';
import {
  Body,
  Button,
  Dialog as UIDialog,
  DialogActions,
  DialogContent,
  DialogFooter,
  DialogProps,
  DialogTitle,
  H2,
  JobDetailsWidget,
  P,
  fontWeight,
} from '@shortlyster/ui-kit';
import { DateFormatter, Location, PoweredByLogo } from 'features/various';
import { Listing } from './types';

type Props = {
  listing: Listing;
  open: boolean;
  onClose: () => void;
};

const Title = styled(DialogTitle)`
  padding-bottom: 0;
`;
const Content = styled(DialogContent)`
  padding-bottom: 0;
  padding-top: 0;
`;

const Dialog = styled(UIDialog)<DialogProps>`
  word-break: break-word;

  ${Content} ${H2} {
    margin-bottom: 1rem;
  }
`;

const ClosedInfo = styled(P)`
  margin-top: 1.5rem;
`;
const StyledLocationSalary = styled.div`
  margin-top: 1.5rem;
  padding-bottom: 2.25rem;
  font-weight: ${fontWeight('bold')};
`;

const formatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  minimumFractionDigits: 0,
});

const JobDetailsModal: React.FC<Props> = ({ listing, open, onClose }) => {
  const {
    closedAt,
    description,
    status,
    locations,
    salaryExpectation,
    organisation: { logoUrl } = {},
  } = listing;

  return (
    <Dialog open={open} onClose={onClose}>
      <Title onClose={onClose}>
        <JobDetailsWidget listing={listing} logoURL={logoUrl} />
        {status === 'closed' && (
          <ClosedInfo>
            This job is no longer accepting applicants.
            <br />
            The employer closed this job on <DateFormatter date={closedAt} format="d mmmm yyyy" />.
          </ClosedInfo>
        )}
        <StyledLocationSalary>
          <Location locations={locations} />
          {salaryExpectation && (
            <>
              {' '}
              &bull; {formatter.format(salaryExpectation.min)} -{' '}
              {formatter.format(salaryExpectation.max)}
            </>
          )}
        </StyledLocationSalary>
      </Title>
      <Content>
        <H2>Job Summary</H2>
        <Body>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </Body>
      </Content>
      <DialogActions>
        <Button onClick={onClose} data-testid="continue-button">
          Continue
        </Button>
      </DialogActions>
      <DialogFooter>
        <PoweredByLogo />
      </DialogFooter>
    </Dialog>
  );
};

export default JobDetailsModal;
