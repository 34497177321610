import styled from 'styled-components';
import { field, InputProps } from 'a-plus-forms';
import {
  LocationServiceSelect as LocationSelect,
  LocationServiceSelectProps as LocationSelectProps,
} from '@shortlyster/forms-kit';
import React from 'react';
import { apolloClientWithAuth0 } from 'src/lib/graphql';
import { getLocationSuggestions, getResolvedLocation } from '../../../lib/forms/LocationPickerUtil';
import CheckboxLabelAligned from './CheckboxLabelAligned';

// @ts-ignore
const SmolCheckbox = styled(CheckboxLabelAligned)`
  margin-top: 0.5rem;
`;

type Location = {
  authorizedForWork?: boolean;
  country: string;
  state?: string;
  city?: string;
};

export interface Props extends InputProps {
  accepts?: LocationSelectProps['accepts'];
  onChange?: (value: Location) => void;
  placeholder?: string;
  value?: Location;
  noResultsMessage?: string;
}

const LocationPicker: React.FC<Props> = props => {
  const { accepts, onChange, placeholder, value: location, noResultsMessage } = props;
  const client = apolloClientWithAuth0();

  return (
    <div>
      <LocationSelect
        accepts={accepts}
        layout={null}
        onChange={onChange}
        placeholder={placeholder}
        value={location}
        noResultsMessage={noResultsMessage}
        onResolve={getResolvedLocation(client)}
        onSuggest={getLocationSuggestions(client)}
      />
      {location && (
        <SmolCheckbox
          key={location.country}
          label={`I require sponsorship to work in ${location.country}`}
          name="authorizedForWork"
          value={location.authorizedForWork}
        />
      )}
    </div>
  );
};

export default field<Props>({ nested: true })(LocationPicker);
