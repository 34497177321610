import { pxToEm, pxToRem } from '../utils/px_to_relative_unit';
import sizes from './sizes';
import bps from './breakpoints';
import rootFontSizes from './root_font_sizes';

const shared = {
  color: {
    secondary: '#06b5f9',
  },
  spacing: {
    [sizes.default]: rootFontSizes.app,
  },
  typography: {
    family: {
      fallback: 'system-ui, sans-serif',
    },
    size: {
      [sizes.default]: rootFontSizes.app,
    },
  },
};

export default Object.freeze({
  // TODO: use the newer media query ranges:
  // https://drafts.csswg.org/mediaqueries/#mq-ranges. For this we need to
  // implement `postcss-preset-env` (https://preset-env.cssdb.org), which
  // requires changes to webpaca. But then we have access to a load of nice
  // CSS features :)
  breakpoint: {
    upToPhone: `max-width: ${pxToEm(bps.phone)}`,
    fromPhone: `min-width: ${pxToEm(bps.phone + 1)}`,
    upToTablet: `max-width: ${pxToEm(bps.tablet)}`,
    fromTablet: `min-width: ${pxToEm(bps.tablet + 1)}`,
    upToDesktop: `max-width: ${pxToEm(bps.desktop)}`,
    fromDesktop: `min-width: ${pxToEm(bps.desktop + 1)}`,
  },
  spacing: {
    [sizes.default]: pxToRem(shared.spacing.default), // 16px
    [sizes.large1x]: pxToRem(shared.spacing.default + 8), // 24px
    [sizes.large2x]: pxToRem(shared.spacing.default + 16), // 32px
    [sizes.large3x]: pxToRem(shared.spacing.default + 24), // 40px
    [sizes.small1x]: pxToRem(shared.spacing.default - 4), // 12px
    [sizes.small2x]: pxToRem(shared.spacing.default - 8), // 8px
    [sizes.small3x]: pxToRem(shared.spacing.default - 12), // 4px
  },
  typography: {
    family: {
      default: `'DM Sans', ${shared.typography.family.fallback}`,
      heading: `'DM Sans', ${shared.typography.family.fallback}`,
    },
    lineHeight: '1.375',
    size: {
      [sizes.default]: `${rootFontSizes.browser}px`, // 18px
      [sizes.large1x]: pxToRem(shared.typography.size.default + 4), // 22px
      [sizes.large2x]: pxToRem(shared.typography.size.default + 12), // 30px
      [sizes.large3x]: pxToRem(shared.typography.size.default + 19), // 37px
      [sizes.large4x]: pxToRem(shared.typography.size.default + 35), // 53px
      [sizes.small1x]: pxToRem(shared.typography.size.default - 3), // 15px
      [sizes.small2x]: pxToRem(shared.typography.size.default - 4), // 14px
      [sizes.small3x]: pxToRem(shared.typography.size.default - 6), // 12px
    },
    weight: {
      default: 400,
      bold: 700,
      light: 300,
    },
  },
});
