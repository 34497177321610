import { useAuth0 } from '@auth0/auth0-react';

/**
 * Check whether current user has `job-hunter` access
 * Users will not have the access until they complete personal details
 * To append app_metadata and user_metadata may require to add custom claims to a token
 * ref: https://auth0.com/docs/get-started/apis/scopes/sample-use-cases-scopes-and-claims#add-custom-claims-to-a-token
 */
export default (): Boolean => {
  const { user } = useAuth0();

  return Object.entries(user || {})?.some(([key, value]) => {
    if (key.includes('app_metadata')) {
      return value?.accesses?.genericApps?.some(app => app?.appCode === 'job-hunter');
    }
    return false;
  });
};
