import { formatLocation } from 'src/lib/utils';
import { Location as LocationType } from '../types';

type Props = {
  locations?: LocationType[];
};

const Location = ({ locations }: Props) => (
  <>{locations?.length > 0 ? locations.map(l => formatLocation(l)).join('; ') : 'N/A'}</>
);

export default Location;
