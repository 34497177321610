import * as React from "react";
import { Box } from "grommet";
import { Tag } from "@shortlyster/ui-kit/dist/components/Select/Tag";

// eslint-disable-next-line no-unused-vars
type Option = {
  label: string;
  value: string;
};

export const RenderValue = (
  value: Option[],
  updateValue: (value: { value: Option[] }) => void,
  disabled = false
) => {
  const removeValue = (v) => () =>
    updateValue({ value: value.filter((o) => o.value !== v) });

  return (
    value.length > 0 && (
      <Box direction="row" wrap>
        {value.map((v, i) => (
          <React.Fragment key={v.value}>
            {i > 0 ? <Box alignSelf="center">OR</Box> : null}
            <Tag
              value={v.value}
              label={v.label}
              remove={removeValue(v.value)}
              disabled={disabled}
            />
          </React.Fragment>
        ))}
      </Box>
    )
  );
};
