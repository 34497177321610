import { FC } from 'react';

import history from 'lib/history';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const { pathname, search } = history?.location || {};
const returnTo = pathname === '/' ? '' : `${pathname}${search}`;

const SignedInContainer: FC = ({ children }) => <>{children}</>;

export default withAuthenticationRequired(SignedInContainer, {
  returnTo,
});
