import { ReactComponent as TickSVG } from 'styles/icons/tick.svg';
import { ReactComponent as LinkSVG } from 'styles/icons/link.svg';
import { ReactComponent as PencilSVG } from 'styles/icons/pencil.svg';
import { ReactComponent as PlusSVG } from 'styles/icons/fa_plus.svg';
import { ReactComponent as RemoveSVG } from 'styles/icons/fa_remove.svg';
import { ReactComponent as TrashSVG } from 'styles/icons/trash.svg';
import { ReactComponent as ExclamationSVG } from 'styles/icons/fa_exclamation-circle.svg';
import { ReactComponent as BugSVG } from 'styles/icons/fa_bug.svg';
import { ReactComponent as ProfileSVG } from 'styles/icons/profile.svg';
import { ReactComponent as SignoutSVG } from 'styles/icons/signout.svg';
import { ReactComponent as ArrowLeftSVG } from 'styles/icons/arrow-left.svg';
import { ReactComponent as ArrowRightSVG } from 'styles/icons/arrow-right.svg';
import { ReactComponent as ChevronLeftSVG } from 'styles/icons/chevron-left.svg';
import { ReactComponent as ChevronRightSVG } from 'styles/icons/chevron-right.svg';
import { ReactComponent as HamburgerSVG } from 'styles/icons/hamburger.svg';
import { ReactComponent as ActiveSVG } from 'styles/icons/active.svg';
import { ReactComponent as EnvelopeSVG } from 'styles/icons/envelope.svg';
import { ReactComponent as ClosedCardSVG } from 'styles/icons/closed_card.svg';
import { ReactComponent as TickCircleSVG } from 'styles/icons/tick-circle.svg';
import { ReactComponent as HelpCircleSVG } from 'styles/icons/help-circle.svg';
import { ReactComponent as InfoOutlineSVG } from 'styles/icons/info-outline.svg';
import { ReactComponent as StarSVG } from 'styles/icons/star.svg';
import { ReactComponent as StarEmptySVG } from 'styles/icons/star-empty.svg';
import { ReactComponent as UploadSVG } from 'styles/icons/data-upload.svg';
import { ReactComponent as DocumentSVG } from 'styles/icons/document.svg';
import { ReactComponent as DownloadSVG } from 'styles/icons/data-download.svg';

import { ReactComponent as HowShortlysterWorksSVG } from 'styles/icons/how-shortlyster-works.svg';
import { ReactComponent as EmploymentDetailsSVG } from 'styles/icons/employment-details.svg';
import { ReactComponent as QualificationsSVG } from 'styles/icons/qualifications.svg';
import { ReactComponent as JobHappinessSVG } from 'styles/icons/job-happiness.svg';
import { ReactComponent as WorkPreferencesSVG } from 'styles/icons/work-preferences.svg';
import { ReactComponent as JobCharacteristicsSVG } from 'styles/icons/job-characteristics.svg';
import { ReactComponent as WorkEnvironmentSVG } from 'styles/icons/work-environment.svg';

import { ReactComponent as GoogleSVG } from 'styles/icons/social-google.svg';
import { ReactComponent as GithubSVG } from 'styles/icons/social-github.svg';
import { ReactComponent as DribbbleSVG } from 'styles/icons/social-dribbble.svg';
import { ReactComponent as LinkedInSVG } from 'styles/icons/social-linkedin.svg';
import { ReactComponent as GlassdoorSVG } from 'styles/icons/social-glassdoor.svg';

// This is identical to tick, but has fill color imbedded which we need for
// checkbox styles since it uses the svg as a background image
import { ReactComponent as CheckboxTickSVG } from 'styles/icons/checkbox-tick.svg';

const ICONS = {
  cross: RemoveSVG,
  tick: TickSVG,
  link: LinkSVG,
  'tick-circle': TickCircleSVG,
  'help-circle': HelpCircleSVG,
  profile: ProfileSVG,
  signout: SignoutSVG,
  'arrow-left': ArrowLeftSVG,
  'arrow-right': ArrowRightSVG,
  'chevron-left': ChevronLeftSVG,
  'chevron-right': ChevronRightSVG,
  hamburger: HamburgerSVG,
  active: ActiveSVG,
  bug: BugSVG,
  info: InfoOutlineSVG,
  star: StarSVG,
  'star-empty': StarEmptySVG,
  upload: UploadSVG,
  document: DocumentSVG,
  download: DownloadSVG,

  // FA icons susbstitutes
  pencil: PencilSVG,
  plus: PlusSVG,
  remove: RemoveSVG,
  'exclamation-circle': ExclamationSVG,
  trash: TrashSVG,
  envelope: EnvelopeSVG,
  'closed-card': ClosedCardSVG,

  // Large icons
  'how-shortlyster-works': HowShortlysterWorksSVG,
  qualifications: QualificationsSVG,
  skills: EmploymentDetailsSVG,
  assessments: JobHappinessSVG,
  'work-preferences': WorkPreferencesSVG,
  'job-characteristics': JobCharacteristicsSVG,
  'work-environment': WorkEnvironmentSVG,

  // Checkbox tick
  'checkbox-tick': CheckboxTickSVG,

  // SOCIAL icons:
  google: GoogleSVG,
  github: GithubSVG,
  dribbble: DribbbleSVG,
  linkedin: LinkedInSVG,
  glassdoor: GlassdoorSVG,
};

type Props = {
  type: string;
};

export default ({ type }: Props) => {
  const SVG = ICONS[type] || ICONS.bug;
  return <SVG />;
};
