import { Component } from 'react';
import { field } from 'a-plus-forms';
import { TextInput } from '@shortlyster/forms-kit';

type Props = React.ComponentProps<typeof TextInput> & {
  value?: Date | String;
  onChange: Function;
};

const zerofy = (number, size) => {
  let string = `${number}`;

  while (string.length < size) {
    string = `0${string}`;
  }

  return string;
};

const reFormat = (date: Date | string): string => {
  if (date) {
    const cleanDate = new Date(date);
    const month = zerofy(cleanDate.getMonth() + 1, 2);
    const year = cleanDate.getFullYear();

    return [month, year].join('/');
  }

  return '';
};

const normalizeMonth = month => {
  const monthGuess = Number.parseInt(month, 10);
  if (monthGuess < 1) return 1;
  if (monthGuess > 12) return 12;
  return monthGuess;
};

export const validate = value => {
  const year = new Date(value).getFullYear();

  const thisYear = new Date().getFullYear();

  return (year < thisYear - 70 || year > thisYear) && 'Year is out of range';
};

class MonthPicker extends Component<Props> {
  state = { innerValue: undefined };

  onChange = (value: string) => {
    const { value: propsValue, onChange } = this.props;
    const match = value.match(/^\s*([\d]{1,2})(\s*\/?\s*([\d]{1,4}))?/);
    let innerValue = value;

    if (!value && propsValue) {
      onChange(undefined);
    } else if (match) {
      const month = normalizeMonth(match[1]);
      const year = Number.parseInt(match[3], 10);

      onChange(new Date(year, month - 1, 1, 0, 0, 0, 0));

      if (month && year) {
        innerValue = [month, year].join('/');
      }
    }

    this.setState({ innerValue: `${innerValue}`.replace(/[^\d/\s]+/g, '') });
  };

  render() {
    const { value } = this.props;
    const { innerValue } = this.state;

    return (
      <TextInput
        {...this.props}
        value={innerValue || reFormat(value)}
        onChange={this.onChange}
        layout={null}
        // Hack to pass maxLength until TextInput types are fixed
        {...{ maxLength: 7 }}
      />
    );
  }
}

export default field()(MonthPicker);
