import { useQuery } from '@apollo/client';

import { NewMessagesCounts, NewMessagesCountsResponse } from './queries';

/**
 * The `pollInterval` option has reluctantly been added here for the sake of tests.
 * I tried unmounting components and messing with sinon.useFakeTimers but could not
 * stop polling from preventing the test run from completing.
 * We don't have this issue in Trackyr where we use Jest so maybe one day this could
 * be removed, along with the pollInterval props on various components that use it.
 */
const useNewMessagesCountsQuery = ({ pollInterval = 60000 } = {}) => {
  const { data } = useQuery<NewMessagesCountsResponse>(NewMessagesCounts, {
    fetchPolicy: 'cache-and-network',
    pollInterval,
  });

  const counts = data?.candidateApplicationsNewMessagesCounts;
  let totalCount: number;
  if (counts) {
    totalCount = counts.reduce((total, application) => total + application.count, 0);
  }

  return { totalCount };
};

export default useNewMessagesCountsQuery;
