import gql from 'graphql-tag';

export const FetchCandidateProfile = gql`
  query FetchCandidateProfile {
    profile {
      id
      timezone
      emailVerified
    }
    candidateProfile {
      id
      details {
        firstName
        lastName
        email
        phone
        employmentTypes
        location {
          country
          state
          city
        }
        salaryExpectation {
          min
          max
        }
      }
    }
  }
`;

export const RefreshToken = gql`
  mutation RefreshToken {
    refreshToken
  }
`;

export const FetchUserProfile = gql`
  query FetchUserProfile {
    profile {
      id
      firstName
      lastName
      email
      timezone
      emailVerified
    }
  }
`;
