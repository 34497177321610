import React from 'react';
import history from 'lib/history';
import App from 'features/layout/App';
import Verify from 'features/verify/Verify';

const VerifyRouteRedirect = ({ params: { location } }) => {
  const listingId = history.location.pathname.split('/l/')[1];
  history.replace('/verify');

  return (
    <App location={location}>
      <Verify params={{ listingId }} />
    </App>
  );
};

export default VerifyRouteRedirect;
