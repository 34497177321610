import React, { FC } from 'react';

import { Chip, Link, MenuItem, MessagesIcon } from '@shortlyster/ui-kit';

import useNewMessagesCountsQuery from '../useNewMessagesCountsQuery';

type Props = {
  current?: boolean;
  pollInterval?: number;
};

const MessagesMenuItem: FC<Props> = ({ current, pollInterval }) => {
  const { totalCount } = useNewMessagesCountsQuery({ pollInterval });

  return (
    <MenuItem
      icon={MessagesIcon}
      link={Link}
      to="/messages"
      current={current}
      data-analytics-event="View messages page by candidate"
      data-testid="messages-menu-link"
    >
      Messages {totalCount ? <Chip>{totalCount}</Chip> : ''}
    </MenuItem>
  );
};

export default MessagesMenuItem;
