import { ToggleTip, Text, Box } from '@compono/ui';
import { formatLocation } from 'src/lib/utils';
import { Location as LocationType } from '../types';

type Props = {
  locations?: LocationType[];
};

const CompactLocation = ({ locations }: Props) => {
  switch (locations?.length) {
    case 0:
    case undefined:
      return <>N/A</>;
    case 1:
      return <>{formatLocation(locations[0])}</>;
    default:
      return (
        <ToggleTip
          buttonSx={{ margin: '0px' }}
          maxWidth={320}
          icon={<Text sx={{ color: 'primary.100' }}>Locations</Text>}
        >
          <Box
            sx={{
              overflowWrap: 'break-word',
              maxWidth: '288px',
            }}
          >
            <Text font="title">Multiple locations:</Text>
            <ul>
              {locations.map(formatLocation).map(locationText => (
                <li key={locationText}>{locationText}</li>
              ))}
            </ul>
          </Box>
        </ToggleTip>
      );
  }
};

export default CompactLocation;
