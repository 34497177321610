import { apolloSpy, mixpanel } from '@shortlyster/analytics-kit';
import { get } from 'lodash';

type TrackEvent = (event: apolloSpy.EventData) => void;

type Mapping = {
  [name: string]: TrackEvent;
};

const trackPrescreeningInvite = (event: apolloSpy.EventData): void => {
  const { variables, result } = event;

  mixpanel.track('Finish and accept invitation', {
    listingId: get(variables, 'id', undefined),
    listingTitle: get(result, 'acceptInvitation.listing.title', undefined),
  });
};

const trackPrescreeningDraft = (event: apolloSpy.EventData): void => {
  const { variables, result } = event;

  mixpanel.track('Finish and apply draft application', {
    listingId: get(variables, 'listingId', undefined),
    listingTitle: get(result, 'applyFor.listing.title', undefined),
  });
};

const trackCVDelete = () => mixpanel.track('Candidate deleted CV');
const trackCancelDraft = () => mixpanel.track('Cancel job application');

const mapping: Mapping = {
  AcceptInvitation: trackPrescreeningInvite,
  ApplyForJob: trackPrescreeningDraft,
  DeleteCV: trackCVDelete,
  CancelDraft: trackCancelDraft,
};

export default mapping;
