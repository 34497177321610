import gql from 'graphql-tag';

export const GenerateVerificationUrl = gql`
  mutation GenerateVerificationUrl($token: String!, $redirectUrl: String!) {
    generateVerificationUrl(token: $token, redirectUrl: $redirectUrl) {
      ticket
    }
  }
`;

export const ResendVerificationEmail = gql`
  mutation {
    resendVerificationEmail
  }
`;
