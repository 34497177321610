import React from 'react';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import history from 'lib/history';
import { AUTH0_CUSTOM_DOMAIN, AUTH0_SPA_CANDIDATE_CLIENT_ID, AUTH0_ENV_NAME } from '../env';

/**
 * Need to wrap the Auth0Wrapper with BrowserRouter
 * As such to have access to the session history of the application
 */
export const Auth0Wrapper: React.FC = ({ children }) => {
  /**
   * Take users back to the route they intended to access before authentication
   */
  const onRedirectCallback = (appState: AppState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={AUTH0_CUSTOM_DOMAIN}
      clientId={AUTH0_SPA_CANDIDATE_CLIENT_ID}
      connection={`compono-connection-${AUTH0_ENV_NAME}`}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience="compono-api"
      scope="read:all"
    >
      <>{children}</>
    </Auth0Provider>
  );
};
