import styled, { keyframes, css } from 'styled-components';

const mountKeyframes = keyframes`
0% {
  opacity: 0;
  max-height: 0;
}
25% {
  opacity: 0;
  max-height: 100%;
}
100% {
  opacity: 1;
  max-height: 100%;
}
`;
const mountAnimation = css`
  animation: ${mountKeyframes} 0.4s ease-in;
`;

const unmountKeyframes = keyframes`
0% {
  max-height: 100%;
  opacity: 1;
}
75% {
  max-height: 100%;
  opacity: 0;
}
100% {
  max-height: 0;
  opacity: 0;
}
`;
const unmountAnimation = css`
  animation: ${unmountKeyframes} 0.4s ease-out;
`;

type MountUnmountProps = {
  mounting?: () => void; // function to update state on animation finish
  unmounting?: () => void; // function to update state on animation finish
};

const Wrapper = styled.div<MountUnmountProps>`
  ${p => p.unmounting && unmountAnimation}
  ${p => p.mounting && mountAnimation}
`;

const AnimationMountWrap: React.FC<MountUnmountProps> = ({ children, unmounting, mounting }) => (
  <Wrapper mounting={mounting} unmounting={unmounting} onAnimationEnd={mounting || unmounting}>
    {children}
  </Wrapper>
);

export default AnimationMountWrap;
