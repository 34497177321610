import styled from 'styled-components';
import SVG from './Svg';

export type Props = {
  type?: string;
  color?: string;
  className?: string;
  aliased?: boolean;
  isUnstyled?: boolean;
} & React.HTMLAttributes<HTMLElement>;

const SVGIcon = styled.i`
  display: inline-block;
  position: relative;
  min-height: 0.75em; /* a capital letter size */
  min-width: 0.75em;
  line-height: inherit;
  vertical-align: baseline;

  svg {
    position: absolute;
    top: -14%;
    left: -11%;
    width: 120%; /* restoring back to 1em size */
    height: 120%;

    path {
      fill: currentColor;
      stroke: transparent;
    }
  }
`;

const Icon: React.FC<Props> = props => {
  const { isUnstyled = false, type = '', ...rest } = props;
  const child = <SVG type={type} />;

  return isUnstyled ? <i {...rest}>{child}</i> : <SVGIcon {...rest}>{child}</SVGIcon>;
};

export default styled(Icon)``;
