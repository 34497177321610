import styled from 'styled-components';
import { color } from '@shortlyster/ui-kit';

const Table = styled.table`
  padding: 1em 0;
  color: ${color('dark-2')};
  width: 100%;

  thead {
    display: none;

    th {
      vertical-align: top;
    }
  }

  @media (${p => p.theme.tabletPortraitAndUp}) {
    display: table;
    border-collapse: collapse;

    thead {
      display: table-header-group;

      th {
        text-align: left;
        padding-bottom: 1em;
      }
    }
  }

  tbody {
    tr {
      display: flex;
      position: relative;
      flex-direction: column;
      & + & {
        margin-top: 1.25em;
      }

      @media (${p => p.theme.tabletPortraitAndUp}) {
        display: table-row;
        flex-direction: unset;

        td:first-child {
          position: static;
          min-width: 1.25em;
        }
      }
    }

    td {
      display: block;
      line-height: 1.5;
      vertical-align: top;
      text-transform: capitalize;
      & + & {
        margin-left: 1.5em;
      }

      @media (${p => p.theme.tabletPortraitAndUp}) {
        display: table-cell;
        margin-left: 0;
      }
    }
  }
`;

export default Table;
