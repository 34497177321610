import { ApolloError } from 'apollo-client';
import gql from 'graphql-tag';
import { useEffect } from 'react';
import { useMutation } from '@apollo/client';

import { Application } from 'features/application/types';

type PreScreeningAnswer = {
  questionId: String;
  answer: Boolean;
};

export type CreateDraftApplicationArgs = {
  listingId: string;
  preScreeningAnswers?: PreScreeningAnswer[];
  attachmentIds?: string[];
  referrerUrl?: string;
  provider?: string;
  portalId?: string;
};

export type ApplicationResult = {
  application: Application | undefined;
};

export const CreateDraftApplication = gql`
  mutation CreateDraftApplication(
    $listingId: ID!
    $preScreeningAnswers: [PreScreeningAnswersInput]
    $attachmentIds: [ID!]
    $referrerUrl: String
    $provider: String
    $portalId: String
  ) {
    application: createDraft(
      listingId: $listingId
      preScreeningAnswers: $preScreeningAnswers
      attachmentIds: $attachmentIds
      referrerUrl: $referrerUrl
      provider: $provider
      portalId: $portalId
    ) {
      id
      sections {
        assessments
        details
        prescreening
        qualifications
        skills
      }
      status
      listing {
        id
        title
        company
        description
        locations {
          country
          state
          city
        }
      }
    }
  }
`;

export const CreateDraftApplicationNewUserGql = gql`
  mutation CreateDraftApplication(
    $listingId: ID!
    $preScreeningAnswers: [PreScreeningAnswersInput]
    $attachmentIds: [ID!]
    $referrerUrl: String
    $provider: String
    $portalId: String
  ) {
    application: createDraft(
      listingId: $listingId
      preScreeningAnswers: $preScreeningAnswers
      attachmentIds: $attachmentIds
      referrerUrl: $referrerUrl
      provider: $provider
      portalId: $portalId
    ) {
      id
      status
      listing {
        id
        title
        company
        description
      }
    }
  }
`;

export type CreateDraftType = {
  data: ApplicationResult;
  loading: boolean;
  error: ApolloError;
};

export const createDraftApplicationMutation = (query = CreateDraftApplication) => {
  const [createDraftApplication, { called, data, loading, error }] = useMutation<
    ApplicationResult,
    CreateDraftApplicationArgs
  >(query, {
    onError: () => {},
  });

  return {
    createDraftApplication,
    called,
    data,
    loading,
    error,
  };
};

/**
 * Creates draft application
 * @param listingId
 * @param createDraftApplication
 */
export const createDraftApplicationCall = (listingId: string, createDraftApplication) => {
  const referrerUrl = window.localStorage.getItem(`referrerUrl/${listingId}`) || document.referrer;
  const portalId = window.localStorage.getItem(`portalId/${listingId}`);
  const provider = window.localStorage.getItem(`provider/${listingId}`);
  createDraftApplication({
    variables: {
      listingId,
      ...(portalId ? { portalId } : {}),
      ...(provider ? { provider } : {}),
      ...(referrerUrl ? { referrerUrl } : {}),
    },
  });
  if (referrerUrl) {
    window.localStorage.removeItem(`referrerUrl/${listingId}`);
  }
  if (portalId) {
    window.localStorage.removeItem(`portalId/${listingId}`);
  }
  if (provider) {
    window.localStorage.removeItem(`provider/${listingId}`);
  }
};

/**
 * Note: if application already exists the existing application is returned,
 * regardless of status (except cancelled which is ignored)
 * @param listingId
 * @param query
 * @constructor
 */
const CreateDraft = (listingId: string, query = CreateDraftApplication): CreateDraftType => {
  const { createDraftApplication, called, data, loading, error } =
    createDraftApplicationMutation(query);

  useEffect(() => {
    createDraftApplicationCall(listingId, createDraftApplication);
  }, [listingId]);

  return { data, loading: loading || !called, error };
};

export default CreateDraft;
