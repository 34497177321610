import styled from 'styled-components';
import { color } from '@shortlyster/ui-kit';

const ShadowBox = styled.div`
  background: ${color('white')};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 1rem;
`;

export default ShadowBox;
