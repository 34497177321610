import styled from 'styled-components';
import ActualSpinner from './Spinner';

const Wrapper = styled.div`
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 400%;
  position: fixed;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 0.2);

  &:before {
    top: 50%;
    left: 50%;
    content: '';
    width: 1.4em;
    height: 1.4em;
    position: absolute;
    background: rgba(0, 0, 0, 0.05);
    transform: translate(-0.7em, -0.7em);
  }
  p {
    position: absolute;
    top: calc(50 % + 3rem);
  }
`;

type Props = {
  children?: any;
  className?: string;
};

// HACK making QAA recognize those as a locker
const Overlay = styled.div``;
const Spinner = styled.div``;

const Locker = ({ children, className }: Props) => (
  <Wrapper className={className}>
    <ActualSpinner />
    {children && <p>{children}</p>}
    <Overlay />
    <Spinner />
  </Wrapper>
);

export default Locker;
