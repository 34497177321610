import { FC } from 'react';
import styled from 'styled-components';
import { useUser } from '@shortlyster/auth-layer';
import { color } from '@shortlyster/ui-kit';

import { User } from 'lib/auth';
import Icon from './Icon';

type Props = {
  className?: string;
};

const ProfileIcon = styled(Icon).attrs({ type: 'profile' })`
  display: inline-block;
  height: 100%;
  width: 100%;
  color: ${color('brand')};
`;

const Avatar: FC<Props> = ({ className }) => {
  const [user] = useUser<User>();
  return <ProfileIcon className={className} title={`${user.firstName} ${user.lastName}`} />;
};

export default styled(Avatar)`
  width: 1em;
  height: 1em;
`;
