import { List } from '@shortlyster/ui-kit';
import { JobDetailsLink, Location } from 'features/various';
import { Listing } from 'features/application/types';

const StripAndTruncate = ({ text }: { text: string }) => {
  const sanitisedText = `${text}`
    .replace(/<[^>]+>/gm, ' ')
    .replace(/\s+/gm, ' ')
    .trim();

  return sanitisedText.length > 300 ? `${sanitisedText.substr(0, 300)}…` : sanitisedText;
};

type Props = { listing: Listing };

const ListingDetails = ({ listing }: Props) => {
  const { company, locations, description } = listing;

  return (
    <>
      <List>
        <List.Item title="Employer">{company}</List.Item>
        <List.Item title="Location">
          <Location locations={locations} />
        </List.Item>
        <List.Item title="Job Summary">{StripAndTruncate({ text: description })}</List.Item>
      </List>
      <br />
      <JobDetailsLink listing={listing} />
    </>
  );
};

export default ListingDetails;
