import styled from 'styled-components'; // eslint-disable-line no-unused-vars
import * as React from 'react';
import * as PropTypes from 'prop-types';
import {
  Slider,
  LabelBlock,
  HelpBlock,
  InputBlock,
  ErrorBlock,
  Layout as BaseLayout,
} from '@shortlyster/forms-kit';
import { color } from '@shortlyster/ui-kit';
import { field } from 'a-plus-forms';

const FieldLayout = styled(BaseLayout)`
  max-width: 43rem;
  margin: 0.5rem auto;

  ${LabelBlock}, ${HelpBlock} {
    display: none;
  }
  ${InputBlock}, ${ErrorBlock} {
    margin-left: 0;
    flex: 1 0 100%;
    max-width: 100%;
  }
`;

const Container = styled.div``;

const StyledSlider = styled(Slider)`
  margin: 0 ${({ theme }) => theme.slider.handleSize / 2}rem;
`;

const Label = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.5rem;
  font-size: ${props => props.theme.typography.size.large1x};
`;

const InnerLabel = styled.div`
  color: ${color('brand')};
  text-transform: capitalize;
  flex: 1 0 48%;

  &:nth-child(2) {
    margin-left: 4%;
    text-align: right;
  }

  text-decoration: ${({ active }) => (active ? 'underline' : 'none')};

  @media (${p => p.theme.tabletPortraitAndUp}) {
    text-decoration: none;
  }
`;

const Help = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.5rem;
`;

const InnerHelp = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};

  &:nth-child(2) {
    margin-left: 1rem;
    text-align: right;
  }

  @media (${p => p.theme.tabletPortraitAndUp}) {
    display: block;
    max-width: 17rem;
  }
`;

type Props = {
  onChange: Function;
  value?: number;
};

type State = {
  show: null | 'left' | 'right';
};

export class PairSlider extends React.Component<Props, State> {
  static contextTypes = {
    APFProps: PropTypes.object,
  };

  render() {
    const { APFProps } = this.context;
    const { labels = [], helpTexts = [] } = APFProps;

    // Note: labels and helpText should be an array with two items
    const [left, right] = labels;
    const [leftHelp, rightHelp] = helpTexts;

    const { value } = this.props;
    // eslint-disable-next-line no-nested-ternary
    const show = value > 3 ? 'right' : value < 3 ? 'left' : null;

    return (
      <Container>
        <StyledSlider layout={null} min={1} max={5} step={0.01} {...this.props} />
        <Label>
          <InnerLabel active={show === 'left'}>{left}</InnerLabel>
          <InnerLabel active={show === 'right'}>{right}</InnerLabel>
        </Label>
        <Help>
          <InnerHelp show={show === 'left'}>{leftHelp}</InnerHelp>
          <InnerHelp show={show === 'right'}>{rightHelp}</InnerHelp>
        </Help>
      </Container>
    );
  }
}

export default field({ defaultValue: 3, layout: FieldLayout })(PairSlider);
