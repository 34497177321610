import sortBy from 'lodash/sortBy';
import history from 'lib/history';
import { Location } from '../features/types';

/**
 * A util to return a clean apollo set of data
 * Removes all nulls that aren't allowed to be so
 * removes _typename properties as well
 *
 * @function cleanApollo
 * @param {any} [apolloData] object/array/any data to be tree-patched
 * @param {[string]} [allowedNulls=[]] names of any props allowed to be null
 * @returns {any} properly formatted data, ready for form use
 */
export function cleanApollo(apolloData, allowedNulls = []) {
  if (Array.isArray(apolloData)) {
    return apolloData.map(element => cleanApollo(element, allowedNulls));
  }

  if (apolloData instanceof Object) {
    return Object.keys(apolloData)
      .filter(e => e !== '__typename')
      .reduce((ret, key) => {
        const removeNull = apolloData[key] === null && !allowedNulls.includes(key);

        return removeNull ? ret : { ...ret, [key]: cleanApollo(apolloData[key], allowedNulls) };
      }, {});
  }

  return apolloData;
}

/**
 * Sort a standard schema list.
 * Sorts by `name` by default, or another field name if provided.
 */
export function sortSchemaList(list = [], field = 'name'): Object[] {
  return sortBy(list, field);
}

/**
 * Gets the current page number from the query string using specified key
 */
export function getCurrentPage(queryKey: string = 'page'): number {
  const page = (history.location.query || {})[queryKey];
  return /^\d+$/.test(`${page}`) ? parseInt(page, 10) : 1;
}

/* capitalizes the first letter of a string */
export const capitalize = (word = '') =>
  word ? `${word.charAt(0).toUpperCase()}${word.slice(1)}` : '';

export const formatLocation = ({ city, state, country }: Location) =>
  [city, state, country].filter(Boolean as any).join(', ');

/* camelCaseWords
 * remove separator and capitalise subsequent words
 */
export const camelCaseWords = (string?: string, separator = ' ') =>
  string &&
  string
    .split(separator)
    .map((word, i) => (i ? word[0].toUpperCase() + word.slice(1) : word))
    .join('');

/**
 * Repeats a function x times and returns result as an array
 */
export const times = (count, fn) => Array(...Array(count)).map((_, i) => fn(i));
