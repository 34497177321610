import styled from 'styled-components'; // eslint-disable-line no-unused-vars
import * as React from 'react';
import * as PropTypes from 'prop-types';
import {
  Slider,
  LabelBlock,
  HelpBlock,
  InputBlock,
  ErrorBlock,
  Layout as BaseLayout,
} from '@shortlyster/forms-kit';
import { field } from 'a-plus-forms';
import { color } from '@shortlyster/ui-kit';

const FieldLayout = styled(BaseLayout)`
  max-width: 43rem;

  ${LabelBlock}, ${HelpBlock} {
    display: none;
  }
  ${InputBlock}, ${ErrorBlock} {
    margin-left: 0;
    flex: 1 0 100%;
    max-width: 100%;
  }
`;

const Container = styled.div``;

const StyledSlider = styled(Slider)`
  margin: 0 ${({ theme }) => theme.slider.handleSize / 2}rem;
`;

const Label = styled.label`
  display: block;
  text-align: center;
  font-size: ${props => props.theme.typography.size.large1x};
  color: ${color('brand')};
  margin-bottom: 1rem;
`;

const Help = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.5rem;
`;

const InnerHelp = styled.div`
  color: ${color('brand')};
  text-transform: capitalize;
`;

type Props = {
  value?: number;
};

export class HiloSlider extends React.Component<Props> {
  static contextTypes = {
    APFProps: PropTypes.object,
  };

  render() {
    const { APFProps } = this.context;
    const { labels = [] } = APFProps;
    const [label = ''] = labels;

    return (
      <Container>
        <Label>{label}</Label>
        <StyledSlider layout={null} min={1} max={5} step={0.01} {...this.props} />
        <Help>
          <InnerHelp>Low</InnerHelp>
          <InnerHelp>High</InnerHelp>
        </Help>
      </Container>
    );
  }
}

export default field({ defaultValue: 1, layout: FieldLayout })(HiloSlider);
