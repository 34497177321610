import styled from 'styled-components';
import { SkillSelectNew } from '../../../skills-kit';
import { field, InputProps } from 'a-plus-forms';
import { EmptyLayout, AsyncSelectMultiProps } from '@shortlyster/forms-kit';
import { Component } from 'react';
import { Text } from '@compono/ui';

// Hide 'OR' from between SkillSelect value pills
const StyledSkillSelect = styled(SkillSelectNew)`
  .vCejL {
    display: none;
  }

  &[data-invalid='true'] {
    button[class*='Select__StyledSelectDropButton'] {
      background: #fff5f5 !important;
      color: #ef4444;
    }
  }
`;

const MAX_SKILLS_ALLOWED = 50;

class FormattedSkillSelect extends Component<AsyncSelectMultiProps & InputProps> {
  render() {
    const skillsCount = this.props.value?.length;
    return (
      <>
        <StyledSkillSelect
          {...this.props}
          error={skillsCount > MAX_SKILLS_ALLOWED ? 'please remove some skills' : ''}
          errorMessage={skillsCount > MAX_SKILLS_ALLOWED}
          layout={EmptyLayout}
          noResultsMessage="No matching results"
        />
        {skillsCount > 0 && (
          <Text size="sm" sx={{ marginTop: 10, color: 'black.60' }}>
            {skillsCount} {skillsCount === 1 ? 'skill' : 'skills'} (max 50)
          </Text>
        )}
      </>
    );
  }
}

export default field<AsyncSelectMultiProps>()(FormattedSkillSelect);
