import { Component, createRef } from 'react';
import styled from 'styled-components';
import { breakpoint, color } from '@shortlyster/ui-kit';

const Container = styled.div<{ hasTip: boolean }>`
  cursor: ${p => (p.hasTip ? 'pointer' : 'default')};
  position: relative;
`;

const Tip = styled.div<{ x: number; y: number }>`
  right: -5rem;
  top: 1.5rem;
  z-index: 30;
  width: 18rem;
  padding: 0.5rem;
  position: absolute;
  border-radius: 0.25rem;
  color: ${color('white')};
  background-color: ${color('black')};

  &:before {
    width: 0;
    height: 0;
    content: ' ';
    right: 5rem;
    top: -0.5rem;
    position: absolute;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.5rem solid ${color('black')};
  }

  @media ${breakpoint('minDesktop')} {
    right: auto;
    left: -5rem;
    width: 28rem;

    &:before {
      right: auto;
      left: 5.25rem;
    }
  }
`;

type Props = {
  className?: String;
  children: any;
  text?: String;
};

type State = {
  hidden: Boolean;
  x: Number;
  y: Number;
};

class Tooltip extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.ref = createRef();
  }

  state = { hidden: true, x: 0, y: 0 };

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
    window.addEventListener('blur', this.handleClick);
    window.addEventListener('scroll', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
    window.removeEventListener('blur', this.handleClick);
    window.removeEventListener('scroll', this.handleClick);
  }

  handleClick = (event: any) => {
    const { current } = this.ref;

    if (event.type !== 'blur' && current && current.contains(event.target)) return;
    this.hideTip();
  };

  hideTip = () => {
    const { hidden } = this.state;
    if (!hidden) this.setState({ hidden: true });
  };

  showTip = () => {
    const { text } = this.props;
    const { current } = this.ref;
    const { hidden } = this.state;
    const { bottom, left } = current.getBoundingClientRect();

    if (!text) return;
    if (hidden) this.setState({ hidden: false, x: left, y: bottom });
  };

  render() {
    const { className, children, text } = this.props;
    const { hidden, x, y } = this.state;

    return (
      <Container
        ref={this.ref}
        hasTip={text != null}
        className={className}
        onClick={this.showTip}
        data-testid="tooltip-container"
      >
        {text && !hidden && (
          <Tip x={x} y={y}>
            {text}
          </Tip>
        )}
        {children}
      </Container>
    );
  }
}

export default styled(Tooltip)``;
