import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Locker } from 'features/various';
import { Box, Button, Text } from '@compono/ui';
import { breakpoint, Link } from '@shortlyster/ui-kit';
import styled from 'styled-components';
import {
  FetchCandidateProfile,
  FetchCandidateProfileData,
  FetchUserProfile,
  FetchUserProfileData,
  User,
} from 'lib/auth';
import { useUser } from '@shortlyster/auth-layer';
import appAccessValidation from 'lib/auth/appAccessValidation';
import {
  createDraftApplicationCall,
  createDraftApplicationMutation,
  CreateDraftApplicationNewUserGql,
} from 'features/apply/useFetchAndApply';
import { ResendVerificationEmail } from './queries';
import { AutoVerify } from './AutoVerify';

const titleStyle: any = {
  textAlign: 'center',
  fontWeight: '700',
  fontSize: '32px',
  marginTop: '64px',
  color: 'black.80',
};

const boxStyle: any = {
  textAlign: 'center',
};

const ActionButtons = styled.div`
  margin-top: 32px;

  @media ${breakpoint('minTablet')} {
    margin-left: 225px;
    margin-right: 225px;
  }

  @media ${breakpoint('minDesktop')} {
    margin-left: 225px;
    margin-right: 225px;
  }

  @media ${breakpoint('maxMobile')} {
    margin-left: 0;
    margin-right: 0;
  }

  ${Link} {
    float: right;
    padding-top: 10px;
    @media ${breakpoint('maxMobile')} {
      width: 100%;
    }
  }
`;

const Verify = ({ params: { listingId } }) => {
  const [user] = useUser<User>();
  const isCandidateUser: Boolean = appAccessValidation();
  const {
    data,
    loading: profileLoading,
    refetch,
  } = useQuery<FetchCandidateProfileData | FetchUserProfileData>(
    isCandidateUser ? FetchCandidateProfile : FetchUserProfile,
    {
      notifyOnNetworkStatusChange: true,
    }
  );
  const [resendVerificationEmail, { loading }] = useMutation(ResendVerificationEmail, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: isCandidateUser ? FetchCandidateProfile : FetchUserProfile }],
  });

  const { createDraftApplication, called } = createDraftApplicationMutation(
    CreateDraftApplicationNewUserGql
  );

  const token = window.sessionStorage.getItem('token');
  const redirectTo = window.sessionStorage.getItem('redirect');

  if (listingId && !called) {
    createDraftApplicationCall(listingId as string, createDraftApplication);
  }

  if (data?.profile?.emailVerified) {
    window.location.pathname = redirectTo || '/profile';
  }

  return (
    <>
      {token && <AutoVerify token={token} redirectTo={redirectTo} />}
      {!token && (loading || profileLoading) && <Locker />}
      {!token && !(loading || profileLoading) && (
        <Box sx={boxStyle}>
          <Text sx={titleStyle}>Verify your email to continue</Text>
          <Text sx={{ marginTop: '32px' }}>
            To protect your privacy, we need to verify your email before we go any further.
          </Text>
          <Text sx={{ marginTop: '32px' }}>
            We’ve sent an email to <b>{user?.email}</b>
          </Text>
          <Text sx={{ marginTop: '32px' }}>To continue, click the link in that email.</Text>
          <Text sx={{ marginTop: '32px' }}>
            If you have already clicked the link on another device, refresh this page to continue.
          </Text>
          <ActionButtons>
            <Button
              data-testid="verify-refresh-button"
              sx={{ float: 'left' }}
              onClick={() => refetch()}
            >
              Refresh
            </Button>
            {/* Current Version of UI library has an issue with LinkButton,
             update to LinkButton component once migrated to latest version of UI lib */}
            <Link
              data-testid="verify-resend-email-link"
              onClick={() => {
                resendVerificationEmail();
              }}
              to="#"
            >
              Re-send the link
            </Link>
          </ActionButtons>
        </Box>
      )}
    </>
  );
};

export default Verify;
