/**
 * Custom Fetch
 * Thanks to this legend: https://github.com/jaydenseric/apollo-upload-client/issues/88#issuecomment-468318261
 * Based heavily on the fetch polyfill: https://github.com/github/fetch/blob/master/fetch.js
 */

/**
 * Not sure what it does, but its lifted straight out of the fetch polyfill
 * https://github.com/github/fetch/blob/master/fetch.js#L368-L382
 */
const parseHeaders = (rawHeaders: any) => {
  const headers = new Headers();
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
  preProcessedHeaders.split(/\r?\n/).forEach((line: any) => {
    const parts = line.split(':');
    const key = parts.shift().trim();
    if (key) {
      const value = parts.join(':').trim();
      headers.append(key, value);
    }
  });

  return headers;
};

/**
 *
 * Opt in custom fetch for handling uploads with added uploadProgress and the ability to abort.
 * If `useUpload` is missing from options then the browsers implementation of fetch is used.
 *
 * How to use:
 * ```
 * export const Default = withFileUpload(({ mutate }) => {
 *   const [file, setFile] = useState<null | File>(null);
 *   const [progress, setProgress] = useState<number>(0);
 *
 *   useEffect(() => {
 *     if (!mutate || !file) {
 *       return;
 *     }
 *     let abort: any;
 *     mutate({
 *       variables: {
 *         file
 *       },
 *       context: {
 *         fetchOptions: {
 *           useUpload: true,
 *           onProgress: (ev: ProgressEvent) => {
 *             setProgress(ev.loaded / ev.total);
 *           },
 *           onAbortPossible: (abortHandler: any) => {
 *             abort = abortHandler;
 *           }
 *         }
 *       }
 *     }).catch(err => console.log(err));
 *
 *     return () => {
 *       if (abort) {
 *         abort();
 *       }
 *     };
 *   }, [file]);
 * }
 * ```
 */
export const uploadFetch = (url: string, options: any) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const opts: any = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers: parseHeaders(xhr.getAllResponseHeaders() || ''),
      };
      opts.url = 'responseURL' in xhr ? xhr.responseURL : opts.headers.get('X-Request-URL');
      const body = 'response' in xhr ? xhr.response : (xhr as any).responseText;
      resolve(new Response(body, opts));
    };
    xhr.onerror = () => {
      reject(new TypeError('Network request failed'));
    };
    xhr.ontimeout = () => {
      reject(new TypeError('Network request failed'));
    };
    xhr.open(options.method, url, true);

    if (options.headers) {
      Object.keys(options.headers).forEach(key => {
        xhr.setRequestHeader(key, options.headers[key]);
      });
    }

    if (xhr.upload) {
      xhr.upload.onprogress = options.onProgress;
    }

    if (options.onAbortPossible) {
      options.onAbortPossible(() => {
        xhr.abort();
      });
    }

    xhr.send(options.body);
  });

const customFetch = (uri: any, options: any) => {
  if (options.useUpload) {
    return uploadFetch(uri, options);
  }
  return fetch(uri, options);
};

export default customFetch;
